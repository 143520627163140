/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// mui
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Star from '@mui/icons-material/Star';
import ShareIcon from '@mui/icons-material/Share';
import CircleIcon from '@mui/icons-material/Circle';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CheckCircle from '@mui/icons-material/CheckCircle';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventNoteIcon from '@mui/icons-material/EventNote';
import HourglassFull from '@mui/icons-material/HourglassFull';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AssignmentAddOutlinedIcon from '@worklist-2/ui/src/assets/icons/assignment_outlined_add.svg';

import { mediaTablet, useAuth, useConfig, useIsTablet, useIsMobile, mediaMobile } from '@worklist-2/core/src';
import { useAppointmentContext } from '../Appointment/AppointmentContext/AppointmentContext';

import DateTime from '../DateTime';
import MobileList from '../MobileBottomDrawer/MobileList/MobileList';
import FormDrawer from '../FormDrawer/FormDrawer';
import MobileBottomDrawer from '../MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';
import MobileStudyDetail from '../MobileBottomDrawer/MobileStudyDetail/MobileStudyDetail';
import NoNotification from '@worklist-2/ui/src/assets/icons/NoNotification.svg';
import NoDataAvailable from '../NoDataAvailable';
import OaiPatientSyncNotification from './OaiPatientSyncNotification';
import { useToastMessageContext } from '../../context/ToastMessageContext';
import moment from 'moment';
import { useDrawersStore } from '../../stores';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const Notifications = ({ setDrawerOpen }) => {
	const { setNotificationReceived, notificationReceived } = useAuth();
	const navigate = useNavigate();
	const __config = useConfig();
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const [notificationDetails, setNotificationDetails] = useState([]);
	const [notificationAvailable, setNotificationAvailable] = useState(false);
	const [selectedForm, setSelectedForm] = useState(null);
	const [appointmentData, setAppointmentData] = useState(null);
	const [openFormDrawer, setOpenFormDrawer] = useState(false);
	const [studyInDetailedView, setStudyInDetailedView] = useState(null);
	const { setSelectedDay, setIsOpen, setIsFullscreen, setSelectedMonth } = useAppointmentContext();
	const { t } = useTranslation('drawer');
	const { setToastMsg } = useToastMessageContext();
	const [showLoading, setShowLoading] = useState(true);
	const phoenixBlumeNewHomepageDesign = useBooleanFlagValue('phoenix-blume-new-homepage-design');
	const setHomePageTab = useDrawersStore(state => state.setHomePageTab);

	const fetchNotifications = () => {
		axios
			.get(`${__config.data_sources.blume}Notification`)
			.then(result => {
				if (result.status === 200) {
					setNotificationDetails(result.data.sort((a, b) => b?.generationTime - a?.generationTime));
					if (result?.data?.length > 0) {
						setNotificationAvailable(true);
					} else {
						setNotificationAvailable(false);
					}
					setShowLoading(false);
				}
			})
			.catch(e => {
				console.error('Error ', e);
			});
	};

	useEffect(() => {
		fetchNotifications();
	}, []);

	useEffect(() => {
		let timeoutId;
		if (notificationReceived) {
			setShowLoading(true);
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				fetchNotifications();
				setNotificationReceived(false);
			}, 10000);
		}
		return () => clearTimeout(timeoutId);
	}, [notificationReceived]);

	const icons = {
		STUDY_COMPLETED: {
			background: '#D3E4D4',
			component: <CheckCircle sx={{ color: '#75B678' }} />,
		},
		STUDY_PROCESSING: {
			background: '#E0EAFA',
			component: <HourglassFull sx={{ color: '#1E8FEA' }} />,
		},
		STUDY_SCHEDULED: {
			background: '#F1E0FA',
			component: <Star sx={{ color: '#C480DC' }} />,
		},
		STUDY_SHARED: {
			background: '#FFEDD0',
			component: <ShareIcon sx={{ color: '#C38323C7' }} />,
		},
		APPOINTMENT_REMINDER: {
			background: '#F1E0FA',
			component: <ScheduleIcon sx={{ color: '#C480DC' }} />,
		},
		APPOINTMENT_CONFIRMED: {
			background: '#D3E4D4',
			component: <EventAvailableIcon sx={{ color: '#75B678' }} />,
		},
		APPOINTMENT_CANCELLED: {
			background: '#CF667914',
			component: <EventBusyIcon sx={{ color: '#CF6679' }} />,
		},
		APPOINTMENT_RESCHEDULED: {
			background: '#D3E4D4',
			component: <EventNoteIcon sx={{ color: '#75B678' }} />,
		},
		DOCUMENT_SHARED: {
			background: '#FFEDD0',
			component: <ShareIcon sx={{ color: '#C38323C7' }} />,
		},
		WORKFLOW_AUTOMATION_NOTIFICATION: {
			background: '#f3c7f1',
			component: <NotificationsIcon sx={{ color: '#A66BA3' }} />,
		},
		STUDY_UPDATED: {
			background: '#f3c7f1',
			component: <NotificationsIcon sx={{ color: '#A66BA3' }} />,
		},
		OAI_PATIENT_UPDATED: {
			background: '#f3c7f1',
			component: <NotificationsIcon sx={{ color: '#A66BA3' }} />,
		},
		FORM_CREATED: {
			background: '#CF667933',
			component: <AssignmentAddOutlinedIcon sx={{ color: '#CF6679' }} />,
		},
		APPOINTMENT_CREATED: {
			background: '#D3E4D4',
			component: <EventAvailableIcon sx={{ color: '#75B678' }} />,
		},
		'': {
			background: '#f3c7f1',
			component: <NotificationsIcon sx={{ color: '#A66BA3' }} />,
		},
	};

	const fetchForm = (id, appointmentId) => {
		axios.get(`${__config.data_sources.blume}Form/${id}`).then(result => {
			if (result?.status === 200 && result?.data) {
				setSelectedForm({
					id: result.data.id,
					patient: result.data?.patient,
					completed: result.data?.completed,
					organizationId: result.data.organizationId,
					status: result.data.status.charAt(0).toUpperCase() + result.data.status.slice(1),
					title: result.data.name,
					type: result.data.type,
					description: result.data.description,
					questionData: result.data.formTemplate?.content,
					lastModified: result.data.lastModified,
					resource: result.data,
					pdfFileName: result.data?.pdfFileName,
					referenceTemplate: result.data?.referenceTemplate,
				});
				setOpenFormDrawer(true);
				const studyId = result.data?.study?.id;
				const patientId = result.data?.patient?.id;

				if (studyId && patientId && appointmentId) {
					const appointment = {
						participant: [
							{
								actor: {
									id: patientId,
									reference: `patient/${patientId}`,
								},
							},
						],
						supportingInformation: [
							{
								id: studyId,
								reference: `ImagingStudy/${studyId}`,
							},
						],
						id: appointmentId,
					};

					setAppointmentData(appointment);
				}
			}
		});
	};

	const readNotification = id => {
		axios.put(`${__config.data_sources.blume}Notification/${id}`).catch(e => {
			console.error('Error', e);
		});
	};

	const handleDeleteNotification = id => {
		axios
			.delete(`${__config.data_sources.blume}Notification/${id}`)
			.then(() => {
				setNotificationDetails(notificationDetails.filter(item => item.id !== id));
			})
			.catch(e => {
				console.error('Error', e);
			});
	};

	const detailedView = details => () => {
		// Read Unread Notification.....
		if (!details?.isSeen) {
			readNotification(details.id);
			details.isSeen = true;
			let temp = true;
			_.map(notificationDetails, notification => {
				temp = temp && notification.isSeen;
			});
			if (temp === true) {
				setNotificationReceived(false);
			}
		}
		if (
			['STUDY_SHARED', 'STUDY_COMPLETED', 'STUDY_PROCESSING', 'STUDY_SCHEDULED'].includes(
				details.properties.NotificationType
			)
		) {
			if (isMobile || isTablet) {
				setStudyInDetailedView({
					study: {
						id: details.properties.InternalStudyId,
					},
				});
			} else {
				// Collapse drawer
				setDrawerOpen('');
				const studyStatus = _.lowerCase(
					details.properties.StudyStatus === 'INPROCESS' ? 'processing' : details.properties.StudyStatus
				);
				navigate(`/studyDetail/${details.properties.InternalStudyId}/${studyStatus}/details`);
			}
		} else if (
			[
				'APPOINTMENT_REMINDER',
				'APPOINTMENT_CONFIRMED',
				'APPOINTMENT_CANCELLED',
				'APPOINTMENT_RESCHEDULED',
				'APPOINTMENT_CREATED',
			].includes(details.properties.NotificationType)
		) {
			if (isMobile) {
				setDrawerOpen('');
				navigate('/appointment');
			} else if (phoenixBlumeNewHomepageDesign) {
				navigate('/home');
				setHomePageTab(0);
			} else {
				setSelectedMonth(new Date(details.properties.scanDate));
				setSelectedDay(new Date(details.properties.scanDate));
				setIsOpen('Scheduler');
				setIsFullscreen(false);
			}
		} else if (details.properties.NotificationType === 'DOCUMENT_SHARED') {
			navigate('/sharedHistory?filter=document');
			setDrawerOpen('');
		} else if (details.properties.NotificationType === 'FORM_CREATED') {
			// Open Form Drawer and display contents
			fetchForm(details.properties.formId, details.properties?.appointmentId);
		} else {
			return false;
		}
	};

	const getTranslatedMessage = (message, notificationType, properties) => {
		switch (notificationType) {
			case 'STUDY_SHARED':
				// message template: "{0} shared a study with you"
				return t(notificationType, {
					sender: message.substring(0, message.indexOf('shared')),
				});
			case 'APPOINTMENT_CANCELLED':
				// message template:"Appointment at {0} on {1} is cancelled."
				return t(notificationType, {
					imagingOrgName: message.substring(message.indexOf('at') + 2, message.indexOf('on')),
					scanDate: message.substring(message.indexOf('on') + 2, message.indexOf('is')),
				});
			case 'APPOINTMENT_CONFIRMED':
				// message template: "Appointment confirmed for {0}"
				return t(notificationType, {
					scanDate: formatDateTime(message.substring(message.indexOf('for') + 3)),
				});
			case 'APPOINTMENT_REMINDER':
				// message template: "You have an upcoming appointment in {0}"
				return t(notificationType, {
					duration: message.substring(message.indexOf(' in') + 3),
				});
			case 'APPOINTMENT_RESCHEDULED':
				// message template: "Appointment at {0} is rescheduled for {1}"
				return t(notificationType, {
					imagingOrgName: message.substring(message.indexOf('at') + 2, message.indexOf('is')),
					scanDate: formatDateTime(message.substring(message.indexOf('for') + 3)),
				});
			case 'APPOINTMENT_CREATED':
				// message template: "Appointment at {0} is scheduled for {1}"
				return t(notificationType, {
					imagingOrgName: message.substring(message.indexOf('at') + 2, message.indexOf('is')),
					scanDate: formatDateTime(message.substring(message.indexOf('for') + 3)),
				});
			case 'DOCUMENT_SHARED':
				// message template: "{0} has shared a document with you!"
				return t(notificationType, {
					userName: message.substring(0, message.indexOf('has')),
				});
			case 'FORM_CREATED':
				if (properties.formType.toLowerCase() === 'clinic') {
					// message template: "Clinical Form needs to be filled - {0}"
					return t(`${notificationType}_clinical`, {
						name: message.substring(message.indexOf('-') + 1),
					});
				}
				// message template: "Registration Form needs to be filled - {0}"
				return t(`${notificationType}_registration`, {
					name: message.substring(message.indexOf('-') + 1),
				});

			default:
				return t(message) ?? message;
		}
	};

	const formatDateTime = stringDate => moment(Date.parse(stringDate)).format('MMM DD, YYYY hh:mm A');

	const getNotificationItem = () =>
		_.map(notificationDetails, details => {
			if (
				details?.properties?.NotificationType === 'OAI_PATIENT_SYNC' ||
				details?.properties?.NotificationType === 'OAI_PATIENT_DATA_SYNC'
			)
				return {
					notificationType: details.properties.NotificationType,
					component: (
						<Box
							key={details?.id}
							sx={{
								marginLeft: isTablet ? '0px' : '15px',
								marginRight: isTablet ? '0px' : '15px',
								marginTop: '10px',
							}}
						>
							<OaiPatientSyncNotification details={details} setNotifications={setNotificationDetails} />
						</Box>
					),
				};
			if (
				details?.properties?.NotificationType === 'DOCUMENT_SHARED' ||
				details?.properties?.NotificationType === 'STUDY_SHARED'
			)
				return null;
			return {
				onDelete: deleteNotification(details),
				onClick: detailedView(details),
				secondaryAction: (
					<Box className="relativeDateSection" sx={{ display: 'flex', alignItems: 'center' }}>
						<DateTime
							datetime={new Date(`${details.generationTime} UTC`)}
							fontColor="#393939"
							sx={{
								paddingRight: '7px',
								fontSize: '12px',
								lineHeight: '16px',
								fontWeight: '400',
								letterSpacing: '0.25px',
							}}
							type="relative_Blume"
						/>
						{!details.isSeen && (
							<CircleIcon
								sx={{
									color: '#42A5F5',
									height: '7px',
									width: '7px',
									marginRight: '5px',
									opacity: 1,
								}}
							/>
						)}
					</Box>
				),
				avatar: {
					children: icons[details.properties.NotificationType].component,
					sx: {
						opacity: 1,
						bgcolor: icons[details.properties.NotificationType].background,
					},
				},
				primary: getTranslatedMessage(details.message, details.properties.NotificationType, details.properties),
				secondary: details.properties.StudyName,
				sx: {
					marginBottom: 0,
					minHeight: '100px',
					'.ExpandableSectionButton': {
						padding: 0,
						display: 'none',
					},
					'& .MuiListItemText-primary': {
						'& .MuiTypography-root': {
							fontWeight: '400',
							width: '100%', // Decreased the width of the message
							color: '#272727',
						},
					},
					'&:hover': {
						'.MuiListItemSecondaryAction-root': {
							top: '50%',
						},
						'.ExpandableSectionButton': {
							display: 'inline-flex',
							visibility: 'visible',
						},
						'.relativeDateSection': {
							display: 'none',
						},
					},
					'& .MuiListItemSecondaryAction-root': {
						right: '4px',
						top: '94%',
					},
					[mediaTablet]: {
						'& .MuiListItemButton-root': { paddingLeft: '0' },
						'& .MuiListItemText-primary': {
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '0.25px',
							color: '#272727',
						},
						'& .MuiListItemText-secondary': {
							fontSize: '12px',
							lineHeight: '20px',
							letterSpacing: '0.25px',
							color: '#393939',
						},
						'& .MuiListItemSecondaryAction-root': {
							right: '0',
							top: 'unset',
							transform: 'unset',
							bottom: '13px',
							height: '20px',
						},
						'&:hover': {
							'.ExpandableSectionButton': {
								display: 'none',
							},
							'.relativeDateSection': {
								display: 'flex',
							},
						},
					},
				},
			};
		}).filter(Boolean);

	const deleteNotification = details => () => {
		if (!details.isSeen) {
			setToastMsg('Unseen notification cannot be deleted!');
			return;
		}

		handleDeleteNotification(details.id);

		if (notificationDetails.length === 1) {
			setNotificationAvailable(false);
		}
	};

	return (
		<>
			{openFormDrawer && (
				<FormDrawer
					appointment={appointmentData}
					data={selectedForm}
					open={openFormDrawer}
					setOpen={setOpenFormDrawer}
					onDrawer
				/>
			)}
			{(isMobile || isTablet) && (
				<MobileBottomDrawer
					background="#ffffff"
					open={Boolean(studyInDetailedView)}
					onClose={() => setStudyInDetailedView(null)}
				>
					<MobileStudyDetail data={studyInDetailedView} isShared="withMe" />
				</MobileBottomDrawer>
			)}
			{notificationAvailable ? (
				<Box sx={{ marginLeft: '10px' }}>
					<MobileList
						background={isTablet ? '#f9f9f9' : '#fafafa'}
						border="0px"
						items={getNotificationItem()}
						subheader={isMobile ? <h2>{t('Notifications')}</h2> : ''}
						sx={{ position: 'relative' }}
						sxListItem={
							isTablet
								? {
										padding: '16px',
										marginTop: '10px',
								  }
								: {}
						}
						sxListItemText={{ textOverflow: 'none', whiteSpace: 'wrap' }}
					/>
				</Box>
			) : showLoading === false ? (
				<Box
					data-testid="noNotifications"
					sx={{
						marginTop: '250px',
						[mediaTablet]: {
							marginLeft: '30px',
						},
						[mediaMobile]: {
							marginTop: '100px',
							marginLeft: '20px',
						},
					}}
				>
					<NoDataAvailable
						direction="column"
						image={{
							imagePath: NoNotification,
							width: '290px',
							height: '250px',
						}}
						primaryText={{
							text: t('No Notifications Found'),
							fontSize: '18px',
							fontWeight: '500',
							color: '#121212',
						}}
						secondaryText={{
							text: t('We will notify you when something new arrives'),
							fontSize: '12px',
							fontWeight: '400',
							color: '#12121299',
						}}
					/>
				</Box>
			) : (
				<Skeleton
					data-testid="notifications-component"
					sx={{
						height: '850px',
						borderRadius: '10px',
						marginLeft: '20px',
						marginRight: '10px',
						[mediaTablet]: {
							marginLeft: 0,
							marginRight: 0,
						},
					}}
					variant="rectangular"
				/>
			)}
		</>
	);
};
export default Notifications;
