/* eslint-disable react/jsx-key */
import React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Typography from '@mui/material/Typography';
import { textStyle } from './OaiPatientSyncNotification';

const EmergencyContactNotification = ({ changeValue, show, notificationDetails }) => {
	const values = typeof changeValue === 'string' ? JSON.parse(changeValue) : changeValue || [];
	if (!show) return null;
	return notificationDetails.properties.operation == 'add'
		? Object?.keys(values)?.map(
				item =>
					item !== 'emergencyContactId' && (
						<Typography sx={{ ...textStyle, marginTop: '8px', display: 'flex' }}>
							<FiberManualRecordIcon sx={{ width: '10px', marginRight: '10px' }} />
							{item} is {values[item]}
						</Typography>
					)
		  )
		: Array.isArray(values)
		? values?.map(item => (
				<Typography sx={{ ...textStyle, marginTop: '8px', display: 'flex' }}>
					<FiberManualRecordIcon sx={{ width: '10px', marginRight: '10px' }} />
					{item.fieldName} from {item.oldValue} to {item.newValue}
				</Typography>
		  ))
		: null;
};

export default EmergencyContactNotification;
