import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';

const Tag = ({ icon, heading, text }) => (
	<Box alignItems={{ xs: 'flex-start', md: 'center' }} display="flex" gap={{ xs: '8px', md: '20px' }}>
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				borderRadius: '4px',
				border: '1px solid #d0d5dd',
				height: { xs: '24px', sm: '56px' },
				width: { xs: '24px', sm: '56px' },
				flexShrink: 0,
				marginTop: { xs: '4px', md: 0 },
			}}
		>
			<Icon data-testid="tag-icon" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				{icon}
			</Icon>
		</Box>
		<Box>
			<Typography color="#475467" fontSize={{ xs: '12px', md: '16px' }} lineHeight="18px">
				{heading}
			</Typography>
			{text}
		</Box>
	</Box>
);

export default Tag;
