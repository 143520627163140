import React, { forwardRef, useState } from 'react';
import { styled } from '@mui/system';
import Input from 'react-phone-input-2';
import Box from '@mui/material/Box';

import 'react-phone-input-2/lib/material.css';

const PhoneInput = ({ onChange, onBlur, name, setSelectedCountryCode }, ref) => {
	const [value, setValue] = useState('');

	return (
		<Box>
			<StyledPhoneInput
				inputProps={{ id: 'schedule-phone', 'data-testid': 'phone-number-input', ref, name }}
				onlyCountries={['us', 'ca', 'in', 'br', 'mx', 'sg', 'vn']}
				specialLabel={null}
				value={value}
				onBlur={onBlur}
				onChange={(val, country, ev) => {
					setValue(val);
					onChange(ev);
					if (setSelectedCountryCode && country?.countryCode) {
						setSelectedCountryCode(country.countryCode.toUpperCase());
					}
				}}
			/>
		</Box>
	);
};

const StyledPhoneInput = styled(Input)(
	() => `
	display: flex;

	.form-control {
		color: #101828;
		background-color: #fff;
		height: 40px;
		width: 100%;
		padding: 8px 12px;
		border: 1px solid #D0D5DD;
		// margin: 0 0 0 126px;
		margin: 0 0 0 68px;
	}

	.form-control:focus, .form-control:hover, .selected-flag:focus { 
		border: 1px solid #D0D5DD;
		box-shadow: none;
	}

	.flag {
		height: 21px;
		width: 24px;
	}

	.flag-dropdown {
		margin: 0 6px 0 0;
	}

	.country-list {
		z-index: 10000000 !important;
	}

	.selected-flag {
		background-color: #fff;
		height: 40px;
		// width: 118px;
		width: 60px;
		border: 1px solid #D0D5DD;
	}

	// Hide label
	.special-label { 
		display: none;
	}
`
);

export default forwardRef(PhoneInput);
