import * as yup from 'yup';
import moment from 'moment';

const validationSchema = yup.object().shape(
	{
		location: yup.string().required(),
		startTime: yup.date().required(),
		endTime: yup.date().required(),
		healthcareServiceId: yup.number().required(),
		email: yup.string().email().optional(),
		phone: yup
			.string()
			.transform(value => value.replace(/\s+/g, ''))
			.matches(/^(?:\+1\s?)?(\(\d{3}\)|\d{3})[\s\-]?\d{3}[\s\-]?\d{4}$/, 'Phone number is not valid')
			.when('phone', val => {
				if (val?.length > 0) {
					return yup.string().min(8, 'Phone number must be of at least 8 digits').required('');
				}
				return yup.string().notRequired();
			}),
		referringPhysician: yup
			.object()
			.shape({
				name: yup.string().required(),
				id: yup.string().required(),
			})
			.nullable()
			.required('Referring physician is required'),
		firstName: yup.string().required(),
		lastName: yup.string().required(),
		pin: yup.number().optional(),
		signupConsent: yup.boolean().optional(),
		blumedescription: yup.string().optional(),
		description: yup.string().required(),
		orderSetId: yup.string().required(),
	},
	[['phone', 'phone']]
);

export default validationSchema;
