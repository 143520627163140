// core
import React, { useState, forwardRef, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// material
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';

//components
import ReferringPhysican from './ReferringPhysician';
import GoBackBtn from '../utils/GoBackBtn';
import Details from '../utils/Details';
import Timer from './Timer';
import PhoneInput from '../utils/PhoneInput';

// store
import { useScheduleStore } from '../../../../stores';

//utils
import { SCHEDULE_APPOINTMENT_TIMEOUT } from '../../../../consts';

const Reservation = ({ form }, ref) => {
	const { t } = useTranslation('scheduling');
	const isLoading = useScheduleStore(state => state.isLoading);
	const selectedFacility = useScheduleStore(state => state.selectedFacility);

	const [timeup, setTimeup] = useState(false);
	const [pauseTimer, setPauseTimer] = useState(false);
	const [, setSelectedCountryCode] = useState('US');

	const { register, trigger, getValues, formState, watch } = form;
	const { location, description, orderSetId, startTime, endTime } = getValues();

	// Watch for changes
	watch();

	useEffect(() => {
		trigger(['lastName', 'firstName', 'email', 'phone', 'startTime', 'endTime']);
	}, []);

	const isValid = useMemo(() => {
		const keys = Object.keys(formState.errors);
		if (!keys.length) {
			return true;
		}

		if ((keys.length === 1 && keys[0] === 'email') || keys[0] === 'phone') {
			return true;
		}

		return false;
	}, [formState]);

	const onTimerComplete = () => {
		setTimeup(true);
		window.location.reload();
	};

	const onSubmitClick = () => {
		setPauseTimer(true);
	};

	return (
		<Box
			ref={ref}
			display="flex"
			flexDirection="column"
			gap={{ xs: '16px', md: '28px' }}
			maxWidth={{ md: '700px' }}
			padding={{
				xs: '20px 16px',
				md: '32px 32px 36px 32px',
			}}
			sx={{
				margin: 'auto',
				background: '#fff',
				boxShadow: '0px 4px 74px 0px rgba(0, 0, 0, 0.03)',
				borderRadius: '8px',
			}}
		>
			<Box>
				<GoBackBtn />
			</Box>
			<Box alignItems="center" display="flex" justifyContent="space-between">
				<Box width={{ xs: '75%' }}>
					<Typography fontSize={{ xs: '16px', md: '24px' }} fontWeight={500} sx={{ color: '#101828' }}>
						{t('Appointment reserved')}
					</Typography>
					<Typography fontSize={{ xs: '14px', md: '18px' }} sx={{ color: '#344054' }}>
						{t('Register now and save your spot before the timer runs out')}
					</Typography>
				</Box>
				<Box width={{ xs: '20%' }}>
					<Timer
						minutes={SCHEDULE_APPOINTMENT_TIMEOUT.minutes}
						paused={pauseTimer}
						seconds={SCHEDULE_APPOINTMENT_TIMEOUT.seconds}
						onComplete={onTimerComplete}
					/>
				</Box>
			</Box>
			<Details
				address={selectedFacility?.address}
				endTime={endTime}
				location={location}
				orderset={description}
				ordersetId={orderSetId}
				startTime={startTime}
			/>
			<Box display="flex" flexDirection="column" gap="24px">
				<Typography fontSize={{ xs: '16px', md: '20px' }} sx={{ color: '#344054' }}>
					{t('Doctor Details')}
				</Typography>
			</Box>
			<ReferringPhysican form={form} />
			<Box display="flex" flexDirection="column" gap="24px">
				<Typography fontSize={{ xs: '16px', md: '20px' }} sx={{ color: '#344054' }}>
					{t('Patient details')}
				</Typography>
				<Box display="flex" flexDirection="column" gap={{ xs: '16px', md: '20px' }}>
					<Box display="flex" flexDirection="column">
						<Typography
							component="label"
							fontSize="14px"
							fontWeight={500}
							htmlFor="schedule-email"
							lineHeight="20px"
							sx={{ marginBottom: '8px' }}
						>
							{t('Email')}
						</Typography>
						<Input
							data-testid="email"
							id="schedule-email"
							placeholder="john@gmail.com"
							{...register('email')}
						/>
					</Box>
				</Box>
				<Box display="flex" flexDirection="column" gap={{ xs: '16px', md: '20px' }}>
					<Box display="flex" flexDirection="column">
						<Typography
							component="label"
							fontSize="14px"
							fontWeight={500}
							htmlFor="schedule-phone"
							lineHeight="20px"
							sx={{ marginBottom: '8px' }}
						>
							{t('Phone number')}
						</Typography>
						<PhoneInput {...register('phone')} setSelectedCountryCode={setSelectedCountryCode} />
						<Typography
							sx={{
								color: '#475467',
								fontSize: '12px',
								lineHeight: '20px',
								marginTop: '6px',
							}}
						>
							{t('Optional')}
						</Typography>
					</Box>
				</Box>
				<Box display="flex" flexDirection="column" gap={{ xs: '16px', md: '20px' }}>
					<Box display="flex" gap="20px">
						<Box display="flex" flexBasis="100%" flexDirection="column">
							<Typography
								component="label"
								fontSize="14px"
								fontWeight={500}
								htmlFor="lastName"
								lineHeight="20px"
								sx={{ marginBottom: '8px' }}
							>
								{t('Family name')}
							</Typography>
							<Input
								data-testid="lastName"
								id="lastName"
								placeholder="Doe"
								{...register('lastName', { required: true })}
							/>
							<Typography
								sx={{
									color: '#475467',
									fontSize: '12px',
									lineHeight: '20px',
									marginTop: '6px',
								}}
							>
								{t('Last name')}
							</Typography>
						</Box>
						<Box display="flex" flexBasis="100%" flexDirection="column">
							<Typography
								component="label"
								fontSize="14px"
								fontWeight={500}
								htmlFor="firstName"
								lineHeight="20px"
								sx={{ marginBottom: '8px' }}
							>
								{t('Given name')}
							</Typography>
							<Input
								data-testid="firstName"
								id="firstName"
								placeholder="Jhon"
								{...register('firstName', { required: true })}
							/>
							<Typography
								sx={{
									color: '#475467',
									fontSize: '12px',
									lineHeight: '20px',
									marginTop: '6px',
								}}
							>
								{t('First name')}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
			<Button
				fullWidth
				color="rsPrimary"
				data-testid="proceed-btn"
				disabled={isLoading || timeup || !isValid}
				sx={{ margin: '20px 0 0 0' }}
				type="submit"
				variant="contained"
				onClick={onSubmitClick}
			>
				{t('Proceed')}
			</Button>
		</Box>
	);
};

const Input = styled(InputBase)(
	() => `
    height: 40px;
    width: 100%;
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #D0D5DD;
    background: #FFFFFF;
  }
`
);

export default forwardRef(Reservation);
