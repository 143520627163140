import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import moment from 'moment';

import { useScheduleStore } from '../../../stores';
import validationSchema from './validationSchema';
import Navbar from './Navbar';
import OrderSetScreen from './OrderSetScreen';
import LocationScreen from './LocationScreen';
import BookSlots from './BookSlots';
import Reservation from './Reservation';
import Status from './Status';
import Notes from './Notes';

const Index = () => {
	const currentStep = useScheduleStore(state => state.currentStep);
	const nextStep = useScheduleStore(state => state.nextStep);
	const getOrgDetails = useScheduleStore(state => state.getOrgDetails);
	const orgDetails = useScheduleStore(state => state.orgDetails);
	const bookAppointment = useScheduleStore(state => state.bookAppointment);

	const [searchParams] = useSearchParams();
	const { t } = useTranslation('scheduling');
	const form = useForm({
		mode: 'onChange',
		defaultValues: {
			description: '',
			orderSetId: '',
			location: '',
			startTime: '',
			endTime: '',
			healthcareServiceId: null,
			email: '',
			phone: '',
			firstName: '',
			lastName: '',
			signupConsent: false,
			referringPhysician: { name: '', id: '' },
			blumedescription: '',
		},
		resolver: yupResolver(validationSchema),
	});

	const orgId = searchParams.get('orgId');
	const hideHeading = currentStep > 3;

	useEffect(() => {
		getOrgDetails({ orgId });
	}, [getOrgDetails, orgId]);

	const onSubmit = useCallback(
		async ({
			healthcareServiceId,
			startTime,
			endTime,
			firstName,
			lastName,
			phone,
			email,
			referringPhysician,
			description,
			orderSetId,
			blumedescription,
		}) => {
			try {
				const data = {
					minutesDuration: moment(endTime).diff(startTime, 'minutes'),
					internalOrganizationId: orgId,
					internalHealthcareServiceId: healthcareServiceId,
					start: startTime.toISOString(),
					end: endTime.toISOString(),
					patient: {
						id: -1,
						resourceType: 'Patient',
						active: true,
						name: [{ text: `${lastName} ${firstName}`, family: lastName, given: [firstName] }],
						// gender: 'FEMALE',
						telecom: [
							{ rank: 1, system: 'phone', use: 'mobile', value: phone },
							{ rank: 2, use: '', system: 'email', value: email },
							{ rank: 3, system: 'fax', value: '' },
						],
						extension: [
							{ url: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-race', extension: [] },
							{ url: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-ethnicity', extension: [] },
							{ url: 'http://www.ramsoft.com/fhir/StructureDefinition/employer' },
							{
								url: 'http://www.ramsoft.com/fhir/StructureDefinition/employmentstatus',
								valueCoding: {},
							},
							{
								url: 'http://www.ramsoft.com/fhir/StructureDefinition/specialcourtesy',
								valueCoding: { code: 'NRM', display: 'normal courtesy' },
							},
						],
						contact: [],
						// managingOrganization: { id: '415', reference: 'organization/415', display: 'SUMANTA TEST' },
						referringPhysician: referringPhysician.name,
						referringPhysicianId: referringPhysician.id,
						phoneNumber: phone,
						employmentStatus: {},
						description,
						orderSetId,
						blumedescription,
					},
					description,
					orderSetId,
					blumedescription,
					referringPhysician: referringPhysician.name,
					referringPhysicianId: referringPhysician.id,
				};

				await bookAppointment({ data });

				nextStep();
			} catch (err) {
				console.error(err);
			}
		},
		[bookAppointment, nextStep, orgId]
	);

	// Steps (Ordered)
	const screens = [
		<OrderSetScreen key={0} form={form} />,
		<LocationScreen key={1} form={form} />,
		<BookSlots key={2} form={form} />,
		<Notes key={3} form={form} />,
		<Reservation key={4} form={form} />,
		<Status key={5} form={form} />,
	];

	return (
		<Box
			boxSizing="border-box"
			sx={{ maxWidth: '100%', minHeight: '100vh', backgroundColor: '#f9fafb', paddingBottom: '40px' }}
		>
			<Navbar
				orgLogoSrc={orgDetails?.logo}
				orgName={orgDetails?.name}
				orgWebsite={orgDetails?.website}
				phone={orgDetails?.phone}
			/>
			{!hideHeading && (
				<Typography
					align="center"
					component="h2"
					data-testid="book-appointment-heading"
					fontSize={{ xs: '20px', md: '28px' }}
					fontWeight={500}
					sx={{ margin: '64px 0 24px 0' }}
				>
					{t('Book an appointment with us')}
				</Typography>
			)}
			<Box
				component="form"
				data-testid="schedule-book-appointment-container"
				sx={{ margin: hideHeading ? '64px 0 0 0' : null }}
				onSubmit={form.handleSubmit(onSubmit)}
			>
				{screens[currentStep]}
			</Box>
		</Box>
	);
};

export default Index;
