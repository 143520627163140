import React, { forwardRef, useCallback, useMemo } from 'react';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import moment from 'moment';
import { useScheduleStore } from '../../../../stores';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

const Notes = ({ form }, ref) => {
	const { t } = useTranslation('scheduling');
	const nextStep = useScheduleStore(state => state.nextStep);
	const jumpToStep = useScheduleStore(state => state.jumpToStep);
	const selectedFacility = useScheduleStore(state => state.selectedFacility);

	const { getValues, register } = form;
	const { startTime, endTime } = getValues();
	const apptStartTime = moment(startTime);
	const apptEndTime = moment(endTime);

	const gotToNextStep = useCallback(() => {
		nextStep();
	}, [nextStep]);
	return (
		<Box ref={ref}>
			<Box
				maxWidth={{ md: '700px' }}
				padding={{
					xs: '20px 16px',
					md: '32px 32px 36px 32px',
				}}
				sx={{
					display: 'flex',
					margin: 'auto',
					background: '#fff',
					boxShadow: '0px 4px 74px 0px rgba(0, 0, 0, 0.03)',
					borderRadius: '8px',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
				width={{ xs: '80%' }}
			>
				<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} width={{ xs: '80%' }}>
					<Box
						component="p"
						fontSize={{
							xs: '12px',
							md: '16px',
						}}
						lineHeight={{
							xs: '16px',
							md: '18px',
						}}
						sx={{
							margin: 0,
							fontWeight: 400,
							color: '#475467',
						}}
					>
						{t('Selected Imaging Procedure and Imaging center')}
					</Box>
					<Box
						component="p"
						fontSize={{
							xs: '14px',
							md: '20px',
						}}
						lineHeight={{
							xs: '18px',
							md: '24px',
						}}
						sx={{ margin: '8px 0 6px' }}
					>
						<b>
							{getValues().orderSetId} - {getValues().description} {t('at')}{' '}
							<span style={{ color: '#42A5F5' }}>{getValues().location}</span>
						</b>
					</Box>
					<Box
						component="p"
						fontSize={{
							xs: '12px',
							md: '16px',
						}}
						lineHeight={{
							xs: '16px',
							md: '18px',
						}}
						sx={{
							margin: 0,
							fontWeight: 400,
							color: '#344054',
						}}
					>
						{selectedFacility?.address}
					</Box>
				</Box>
				<Button
					color="info"
					sx={{ color: '#42A5F5' }}
					variant="text"
					width={{ xs: '20%' }}
					onClick={() => jumpToStep(0)}
				>
					{t('CHANGE')}
				</Button>
			</Box>
			<Box
				maxWidth={{ md: '700px' }}
				padding={{
					xs: '20px 16px',
					md: '32px 32px 36px 32px',
				}}
				sx={{
					margin: '30px auto 0',
					background: '#fff',
					boxShadow: '0px 4px 74px 0px rgba(0, 0, 0, 0.03)',
					borderRadius: '8px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
				width={{ xs: '80%' }}
			>
				<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} width={{ xs: '80%' }}>
					<Box
						component="p"
						fontSize={{
							xs: '12px',
							md: '16px',
						}}
						lineHeight={{
							xs: '16px',
							md: '18px',
						}}
						sx={{
							margin: 0,
							fontWeight: 400,
							color: '#475467',
						}}
					>
						{t('Selected date and time')}
					</Box>
					<Typography
						noWrap
						fontSize={{
							xs: '14px',
							md: '20px',
						}}
						fontWeight={500}
						lineHeight={{
							xs: '18px',
							md: '24px',
						}}
						sx={{ margin: '8px 0 6px' }}
					>
						{apptStartTime.isValid() && apptEndTime.isValid()
							? `${apptStartTime.format('DD')} ${t(apptStartTime.format('MMM'))}, ${apptStartTime.format(
									'YYYY (h:mm'
							  )} ${t(apptStartTime.format('a'))} - ${apptEndTime.format('h:mm')} ${t(
									apptEndTime.format('a')
							  )})`
							: null}
					</Typography>
				</Box>
				<Button color="info" sx={{ color: '#42A5F5' }} variant="text" onClick={() => jumpToStep(2)}>
					{t('CHANGE')}
				</Button>
			</Box>
			<Box
				maxWidth={{ md: '700px' }}
				padding={{
					xs: '20px 16px',
					md: '32px 32px 36px 32px',
				}}
				sx={{
					margin: '30px auto 0',
					background: '#fff',
					boxShadow: '0px 4px 74px 0px rgba(0, 0, 0, 0.03)',
					borderRadius: '8px',
				}}
				width={{ xs: '80%' }}
			>
				<Typography
					fontSize={{
						xs: '16px',
						md: '20px',
					}}
					fontWeight={500}
					lineHeight={{
						xs: '18px',
						md: '24px',
					}}
					marginBottom={{
						xs: '8px',
						md: '12px',
					}}
					sx={{
						color: '#101828',
					}}
				>
					Add note (optional){' '}
				</Typography>
				<Typography
					color="#344054"
					fontSize={{
						xs: '14px',
						md: '16px',
					}}
					lineHeight={{
						xs: '16px',
						md: '18px',
					}}
				>
					Please use this space to provide any additional information or notes that you believe would be
					helpful for your upcoming medical appointment
				</Typography>
				<Box
					display="flex"
					flexDirection="column"
					sx={{
						marginTop: '40px',
					}}
				>
					<Typography
						component="label"
						fontSize="14px"
						fontWeight={500}
						htmlFor="blumedescription"
						lineHeight="20px"
						sx={{ marginBottom: '8px' }}
					>
						{t('Notes')}
					</Typography>
					<Textarea
						aria-label="minimum height"
						minRows={8}
						sx={{
							padding: '8px 16px',
							border: '1px solid #D0D5DD',
							borderRadius: '6px',
							outline: 'none',
							fontFamily: 'Roboto',
							fontSize: '16px',
							lineHeight: '24px',
						}}
						{...register('blumedescription')}
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row-reverse',
						marginTop: '30px',
					}}
				>
					<Button
						color="rsPrimary"
						sx={{ margin: '20px 0 0 0', padding: '10px 30px' }}
						variant="contained"
						onClick={gotToNextStep}
					>
						{t('Proceed')}
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

const Textarea = styled(TextareaAutosize)(``);

export default forwardRef(Notes);
