/* eslint-disable no-nested-ternary */
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useScheduleStore } from '../../../../stores';
import FacilityListV2 from '@worklist-2/patientPortal/src/components/Appointment/AppointmentDrawerV2/components/FacilityListV2';
import FormAPIAutocompleteVariant from '@worklist-2/ui/src/components/FormNew/formPartials/FormFieldVariants/variants/FormAPIAutocompleteVariant';
import locationIcon from '@worklist-2/ui/src/assets/img/homepage/location.png';
import {
	getFacilitiesByLocation,
	getLocationLabel,
	searchGooglePlaces,
} from '../../../Appointment/AppointmentDrawerV2/utils';
import { InputAdornment, Skeleton } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { GpsFixedTwoTone } from '@mui/icons-material';
import { debounce } from 'lodash';
import { useConfig, useIsTablet } from '@worklist-2/core';

const LocationScreen = ({ form }, ref) => {
	const nextStep = useScheduleStore(state => state.nextStep);
	const prevStep = useScheduleStore(state => state.prevStep);
	const modalities = useScheduleStore(state => state.modalities);
	const modalityCode = useScheduleStore(state => state.modalityCode);
	const setSelectedFacility = useScheduleStore(state => state.setSelectedFacility);
	const [googleSearchResults, setGoogleSearchResults] = useState([]);
	const [facilities, setFacilities] = useState([]);
	const [isLoadingFacility, setIsLoadingFacility] = useState(false);
	const _filterOptions = createFilterOptions();
	const __config = useConfig();
	const isTablet = useIsTablet();

	const { getValues, setValue } = form;

	const [searchParams] = useSearchParams();
	const { t } = useTranslation(['scheduling', 'appointments']);

	const current_location_option = {
		description: t('Use my current location'),
		place_id: 'current_location',
		value: t('Current location selected'),
	};

	const orgId = searchParams.get('orgId');

	const location = form.watch('location');
	const userLocation = form.watch('userLocation');

	useEffect(() => {
		if (!orgId) return;
		if (!userLocation) {
			form.setValue('userLocation', current_location_option);
			return;
		}
		getFacilitiesByLocation(
			__config,
			userLocation,
			Function.prototype,
			Function.prototype,
			Function.prototype,
			null,
			{
				DicomModalities: modalityCode,
			},
			form,
			setIsLoadingFacility,
			setFacilities,
			orgId
		);
	}, [modalities, orgId, userLocation]);

	const gotToNextStep = useCallback(() => {
		nextStep();
	}, [nextStep]);

	const searchPlaces = useCallback(
		debounce(async query => {
			searchGooglePlaces(query, setGoogleSearchResults, googleSearchResults);
		}, 500),
		[setGoogleSearchResults]
	);

	const onSelectFacility = facility => {
		setValue('location', facility.organizationName);
		setSelectedFacility({ facility });
	};

	return (
		<Box ref={ref} sx={{}}>
			<Box
				maxWidth={{ md: '700px' }}
				padding={{
					xs: '20px 16px',
					md: '32px 32px 36px 32px',
				}}
				sx={{
					margin: 'auto',
					background: '#fff',
					boxShadow: '0px 4px 74px 0px rgba(0, 0, 0, 0.03)',
					borderRadius: '8px',
				}}
				width={{ xs: '80%' }}
			>
				<Box display="flex" justifyContent="space-between">
					<Box display="flex" flexDirection="column" gap="8px">
						<Typography
							color="#344054"
							fontSize={{
								xs: '12px',
								md: '16px',
							}}
							fontWeight={400}
							lineHeight={{
								xs: '14px',
								md: '18px',
							}}
						>
							{t('Selected Imaging Procedure')}
						</Typography>
						<Typography
							color="#101828"
							fontSize={{
								xs: '14px',
								md: '20px',
							}}
							fontWeight={500}
							lineHeight={{
								xs: '16px',
								md: '23px',
							}}
						>
							{getValues().orderSetId} - {getValues().description}
						</Typography>
					</Box>
					<Box>
						<Button
							data-testid="location-back-btn"
							sx={{
								textTransform: 'uppercase',
								color: 'rsPrimary.main',
								fontWeight: 500,
								fontSize: '16px',
							}}
							onClick={prevStep}
						>
							{t('Change')}
						</Button>
					</Box>
				</Box>
			</Box>
			<Box
				maxWidth={{ md: '700px' }}
				padding={{
					xs: '20px 16px',
					md: '32px 32px 36px 32px',
				}}
				sx={{
					background: '#fff',
					boxShadow: '0px 4px 74px 0px rgba(0, 0, 0, 0.03)',
					margin: '30px auto 0',
					borderRadius: '8px',
				}}
				width={{ xs: '80%' }}
			>
				<Box>
					<Typography color="#475467" display="block" fontSize={14} fontWeight={500} mb={1} variant="p">
						{t('User Location')}
					</Typography>
					<FormAPIAutocompleteVariant
						TextFieldProps={{
							placeholder: t('Allow us to find the closest facility around you'),
							sx: {
								maxHeight: '40px',
								padding: '0px 0px !important',
							},
							'data-testid': 'location-select',
							InputProps: {
								startAdornment: (
									<InputAdornment position="start" sx={{ ml: 1.6 }}>
										<img alt="location" height="20px" src={locationIcon} />
									</InputAdornment>
								),
							},
						}}
						formHook={form}
						hideClear={
							Boolean(form.getValues('userLocation')?.place_id === 'current_location') ||
							!form.getValues('userLocation')
						}
						name="userLocation"
						options={googleSearchResults}
						props={{
							isOptionEqualToValue: (option, value) => !value || option?.place_id === value?.place_id,
							getOptionLabel: getLocationLabel,
							renderOption: (props, option) => (
								<li {...props} key={option?.place_id}>
									{option?.description ? (
										option?.place_id === 'current_location' ? (
											<Typography
												sx={{
													marginLeft: '8%',
													fontSize: '14px',
												}}
											>
												<GpsFixedTwoTone fontSize="inherit" /> {option?.description}
											</Typography>
										) : (
											<Typography
												sx={{
													marginLeft: '8%',
													fontSize: '16px',
													color: 'black',
												}}
											>
												{option?.description}
											</Typography>
										)
									) : (
										''
									)}
								</li>
							),
							filterOptions: (options, state) => {
								const results = _filterOptions(options, state);
								if (!results.includes(current_location_option)) {
									results.unshift(current_location_option);
								}
								return results;
							},
						}}
						setOptions={setGoogleSearchResults}
						sx={{
							height: '40px',
							borderRadius: '4px',
							width: '100%',
							background: '#ffffff',
							fontSize: 14,
						}}
						onSearch={searchPlaces}
					/>
					<Typography fontSize={14} fontWeight={500} mt={3} sx={{ color: '#475467', marginBottom: '16px' }}>
						{t('Choose imaging center')}
					</Typography>
					{facilities?.length && !isLoadingFacility ? (
						<FacilityListV2
							fullWidth
							isSelfScheduling
							facilities={facilities}
							facility={location}
							isTablet={isTablet}
							t={t}
							onSelectFacility={facility => () => onSelectFacility(facility)}
						/>
					) : isLoadingFacility ? (
						Array(3)
							.fill(1)
							.map((_d, i) => (
								<Box
									key={i}
									data-testid="progressbar"
									sx={{
										width: '100%',
										border: '1px solid #D0D5DD',
										borderRadius: '8px',
										paddingY: 1.5,
										mt: 1.5,
										display: 'flex',
										alignItems: 'start',
									}}
								>
									<Box sx={{ flex: 1, mx: 2 }}>
										<Skeleton height="20px" variant="text" width="90%" />
										<Skeleton height="5px" sx={{ mt: 1 }} variant="text" width="90%" />
										<Skeleton height="5px" sx={{ mt: 1 }} variant="text" width="50%" />
									</Box>
									<Skeleton
										height="20px"
										sx={{ mr: 1, borderRadius: '8px' }}
										variant="rectangular"
										width="80px"
									/>
								</Box>
							))
					) : (
						<Typography fontSize={15} py={4} sx={{ opacity: 0.5 }} textAlign="center" width="100%">
							{t('No Facilities Available')}
						</Typography>
					)}
				</Box>
				<Box display="flex" justifyContent="flex-end" sx={{ margin: '48px 0 0 0' }}>
					<Button
						color="rsPrimary"
						data-testid="location-next-btn"
						disabled={!getValues().location}
						sx={{
							textTransform: 'uppercase',
							boxShadow: 'none',
							padding: '12px 14px',
							width: { xs: '100%', lg: '150px' },
							display: {
								xs: 'none',
								md: 'block',
							},
						}}
						type="button"
						variant="contained"
						onClick={gotToNextStep}
					>
						{t('Next')}
					</Button>
				</Box>
			</Box>
			<Button
				color="rsPrimary"
				data-testid="location-next-btn"
				disabled={!getValues().location}
				sx={{
					textTransform: 'uppercase',
					boxShadow: 'none',
					padding: '12px 14px',
					width: { xs: '100%', lg: '150px' },
					display: {
						xs: 'block',
						md: 'none',
					},
					position: 'fixed',
					bottom: '32px',
					width: '90%',
					left: '5%',
				}}
				type="button"
				variant="contained"
				onClick={gotToNextStep}
			>
				{t('Next')}
			</Button>
		</Box>
	);
};

export default forwardRef(LocationScreen);
