import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import FormAPIAutocompleteVariant from '@rs-ui/components/FormNew/formPartials/FormFieldVariants/variants/FormAPIAutocompleteVariant';

import { useScheduleStore } from '../../../../stores';

const DownArrow = () => (
	<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<path d="M6 9L12 15L18 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
	</svg>
);

const ReferringPhysician = ({ form }) => {
	const getReferringPhysicians = useScheduleStore(state => state.getReferringPhysicians);

	const { t } = useTranslation('schedule');

	const metaSearchReferringPhysicians = async query => getReferringPhysicians({ searchTerm: query });

	return (
		<Box display="flex" flexDirection="column">
			<Typography
				component="label"
				fontSize="14px"
				fontWeight={500}
				htmlFor="referringPhysician"
				lineHeight="20px"
				sx={{ marginBottom: '8px' }}
			>
				{t('Referring Physician Name')}
			</Typography>
			<FormAPIAutocompleteVariant
				TextFieldProps={{
					sx: {
						padding: 0,
					},
					InputProps: {
						IconComponent: DownArrow,
						placeholder: 'Doe, John DR',
						sx: {
							height: '40px',

							'& input': {
								height: '40px',
								transform: 'translateY(-10px)',
								boxSizing: 'border-box',
							},

							'& .MuiCircularProgress-root': {
								margin: '-20px 0 0 0',
							},
						},
					},
				}}
				formHook={form}
				name="referringPhysician"
				props={{
					isOptionEqualToValue: (option, value) => !value || option?.id === value?.id,
					getOptionLabel: option => (option?.name ? option?.name : ''),
					renderOption: (props, option) => (
						<li {...props} key={option?.id}>
							{option?.name}
						</li>
					),
				}}
				onChange={value => {
					if (!value) form.setValue('referringPhysician', { name: '', id: '' });
				}}
				onSearch={metaSearchReferringPhysicians}
			/>
		</Box>
	);
};

export default ReferringPhysician;
