/* eslint-disable react/jsx-key */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React from 'react';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ClearIcon from '@mui/icons-material/Clear';
import { Checkbox } from '@mui/material';
import { toSentenceCase } from './OaiPatientSyncNotification';
import EmergencyContactNotification from './EmergencyContactNotification';

const ClubbedNotification = ({
	show,
	details,
	selectedClubbed,
	handleCheckboxClick,
	getPatientName,
	t,
	emergencyContacts,
	expandAppointmentView,
}) => {
	if (!show) return null;
	return (details || []).map((detail, i) => {
		const isEmergencyC = detail.properties?.NotificationType === 'OAI_PATIENT_DATA_SYNC';
		const { changeValue: c, operation: o } = detail?.properties || {};
		const accepted = detail?.status === 'accepted' || detail?.properties?.status?.toLowerCase() === 'accepted';
		const rejected =
			detail?.status === 'rejected' ||
			detail?.properties?.status?.toLowerCase() === 'rejected' ||
			detail?.properties?.status?.toLowerCase() === 'updated';

		return (
			<div
				style={{
					padding: expandAppointmentView ? '0px 0px' : '5px 5px',
					minHeight: isEmergencyC ? 'fit-content' : 30,
					fontWeight: 400,
					display: 'flex',
					alignItems: 'start',
					marginTop: expandAppointmentView && '5px',
				}}
			>
				{rejected ? (
					<ClearIcon sx={{ color: '#CF6679', width: '24px', margin: '12px 7px 0px', height: 'auto' }} />
				) : accepted ? (
					<ThumbUpOffAltIcon
						sx={{
							color: '#B0A6DC',
							width: '24px',
							margin: '0 7px',
							height: 'auto',
							transform: 'scale(-1,1)',
							marginTop: '10px',
						}}
					/>
				) : (
					<Checkbox
						// eslint-disable-next-line react/prop-types
						checked={selectedClubbed.includes(detail.id)}
						size={expandAppointmentView ? 'medium' : 'small'}
						sx={{ marginTop: '4px', padding: expandAppointmentView && '5px 5px 0px 0px' }}
						onClick={s => handleCheckboxClick(s, detail.id)}
					/>
				)}
				{isEmergencyC ? (
					<div>
						<p style={{ lineHeight: '95%', fontSize: expandAppointmentView && 14 }}>
							<b>{`${getPatientName()}'s `}</b>
							<u>{t('emergency contact ')}</u>
							<b>
								{o == 'add'
									? typeof c === 'object'
										? c?.name
										: t(JSON.parse(c)?.name)
									: // eslint-disable-next-line react/prop-types
									  emergencyContacts?.find(
											d => d?.emergencyContactId === detail?.properties?.uniqueId
									  )?.name || ''}
							</b>
						</p>
						<EmergencyContactNotification show changeValue={c || {}} notificationDetails={detail} />
					</div>
				) : (
					<p
						style={{
							lineHeight: '15px',
							margin: '12px 0px 0px 0px',
							fontSize: expandAppointmentView && 14,
						}}
					>
						{t(toSentenceCase(detail.properties.fieldName).slice(1))} {t('from')}{' '}
						{t(detail.properties.oldValue) || 'NA'} {t('to')} {t(detail.properties.newValue)}
					</p>
				)}
			</div>
		);
	});
};

export default ClubbedNotification;
