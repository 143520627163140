// core
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

// mockData
import axios from 'axios';

// utils
import { useAuth, generateRandomString, useConfig, useIsTablet } from '@worklist-2/core/src';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { useUserInfoStore, useUserStore } from '../stores';
import * as getUserInfoAPI from '../api/getUserInfo';
import divideObject from '../utils/divideObject';
import { getAllergyDataFromProfile } from '../utils/getAllergyDataFromProfile';
import getChangedFieldsOnEditProfile from '../utils/getChangedFieldsOnEditProfile';
import { LANGUAGE_MAPPING } from '../consts';
import { useToastMessageContext } from './ToastMessageContext';
import sendAnalyticsEvent from '../analytics';
import { LANGUAGE_CHANGE } from '../analytics/eventTypes';

const UPDATE_TYPES = {
	EMERGENCY: 'EMERGENCY',
	PHYSICIAN: 'PHYSICIAN',
	LANGUAGE: 'LANGUAGE',
};

const SettingsViewContext = createContext({});

const SettingsViewContextProvider = ({ children }) => {
	const { loggedInUser, profiles, setProfiles } = useAuth();
	const formData = {
		img: '',
		name: loggedInUser?.fullName,
		firstName: loggedInUser?.firstName,
		middleName: '',
		lastName: '',
		birthDate: '',
		gender: '',
		phone: '',
		email: '',
		address: '',
		addressLine2: '',
		city: '',
		state: '',
		country: '',
		zip: '',
		race: '',
		ethnicity: '',
		birthSex: '',
		language: '',
		ssn: '',
		maritalStatus: '',
		smokingStatus: '',
		alcoholUse: '',
		motherMaidenName: '',
	};
	const __config = useConfig();
	const updateUserInfoInStore = useUserInfoStore(state => state.updateUserInfo);
	const {
		storeProfiles,
		user: storeUser,
		emergencyContacts: storeEContacts,
		physicians: storePhysicians,
		storePrimaryUser,
		storeSelectedLanguage,
		fetchUser: storeFetchUser,
	} = useUserStore(state => ({
		user: state.user,
		storeProfiles: state.profiles,
		storePrimaryUser: state.primaryUser,
		emergencyContacts: state.emergencyContacts,
		physicians: state.physicians,
		storeSelectedLanguage: state.selectedLanguage,
		fetchUser: state.fetchUser,
	}));
	const { setToastMsg } = useToastMessageContext();
	const [emergencyContacts, setEmergencyContacts] = useState([]);
	const [physicians, setPhysicians] = useState([]);
	const [selectedLanguage, setSelectedLanguage] = useState('en');
	const [user, setUser] = useState(formData);
	const [aggregateDetails, setAggregateDetails] = useState({});
	const [leftProfile, setLeftProfile] = useState(null);
	const [rightProfile, setRightProfile] = useState(null);
	const [triggerDocumentSectionRefresh, setTriggerDocumentSectionRefresh] = useState(false);
	const [primaryUser, setPrimaryUser] = useState(null);

	const { t } = useTranslation('profile');
	const isTablet = useIsTablet();
	const { i18n } = useTranslation();

	const updateUser = async (updateType, updatedValue, onSuccess) => {
		let path;
		let value;
		switch (updateType) {
			case UPDATE_TYPES.EMERGENCY:
				path = `/Aggregatedetails/${user?.profileId}/emergencyContacts`;
				value = updatedValue.map(contact => ({
					name: contact.name || '',
					relation: contact.relation || '',
					phone: contact.phone || '',
					email: contact.email || '',
				}));
				break;
			case UPDATE_TYPES.PHYSICIAN:
				path = `/Aggregatedetails/${user?.profileId}/myPhysician`;
				value = updatedValue.map(physician => ({
					name: physician.name || '',
					specialisation: physician.specialisation || '',
					phone: physician.phone || '',
					email: physician.email || '',
				}));
				break;
			case UPDATE_TYPES.LANGUAGE:
				path = '/userSettings';
				value = { Language: updatedValue };
				break;
			default:
				break;
		}

		patchUserInfo([
			{
				operation: 'ADD',
				path,
				value,
			},
		]).then(() => {
			getUserInfo(user.profileId);
			onSuccess && onSuccess();
		});
	};

	useEffect(() => {
		calculateLeftAndRightProfiles();
	}, [calculateLeftAndRightProfiles, user]);

	useEffect(() => {
		if (!storeProfiles || !storeUser) return;
		setUser(storeUser);
		setProfiles(storeProfiles);
		setEmergencyContacts(storeEContacts);
		setPhysicians(storePhysicians);
		setSelectedLanguage(storeSelectedLanguage);
		setAggregateDetails(storePrimaryUser || {});
		setPrimaryUser(storePrimaryUser || {});
		updateUserOnlineStatus(true);
	}, [storeProfiles]);

	const onEmergencyContactCreate = (data, onSuccess) => {
		const uniqueId = uuidv4();
		data.id = uniqueId;
		data.emergencyContactId = uniqueId;
		const emergencyContacts = [...user.emergencyContacts, data];
		updateUser(UPDATE_TYPES.EMERGENCY, emergencyContacts, onSuccess);
	};

	const onEmergencyContactUpdate = (data, onSuccess) => {
		const selectedUser = profiles[user?.profileId];
		const updatedEContacts = user.emergencyContacts.map(emergencyContact => {
			if (emergencyContact.id) {
				return emergencyContact.id === data.id ? data : emergencyContact;
			}

			return emergencyContact.emergencyContactId === data.emergencyContactId ? data : emergencyContact;
		});
		setEmergencyContacts(updatedEContacts);
		updateUser(UPDATE_TYPES.EMERGENCY, emergencyContacts, onSuccess);
		setProfiles(prev => ({
			...prev,
			[user?.profileId]: {
				...selectedUser,
				emergencyContacts: updatedEContacts,
			},
		}));
	};

	const onEmergencyContactDelete = data => {
		const selectedUser = profiles[user?.profileId];
		const updatedEContacts = emergencyContacts.filter(emergencyContact => {
			if (emergencyContact.id) {
				return emergencyContact.id !== data.id;
			}
			return emergencyContact.emergencyContactId !== data.emergencyContactId;
		});
		setEmergencyContacts(updatedEContacts);
		updateUser(UPDATE_TYPES.EMERGENCY, updatedEContacts);
		setProfiles(prev => ({
			...prev,
			[user?.profileId]: {
				...selectedUser,
				emergencyContacts: updatedEContacts,
			},
		}));
	};

	const onReferringPhysicianCreate = data => {
		data.id = generateRandomString(16);
		const updatedPhysicians = isTablet ? [data, ...physicians] : [...physicians, data];
		setPhysicians(updatedPhysicians);
		updateUser(UPDATE_TYPES.PHYSICIAN, updatedPhysicians);
	};

	const onReferringPhysicianUpdate = data => {
		const updatedPhysicians = physicians.map(physician => (physician.id === data.id ? data : physician));
		setPhysicians(updatedPhysicians);
		updateUser(UPDATE_TYPES.PHYSICIAN, updatedPhysicians);
	};

	const onReferringPhysicianDelete = data => {
		const updatedPhysicians = physicians.filter(physician => physician.id !== data.id);
		setPhysicians(updatedPhysicians);
		updateUser(UPDATE_TYPES.PHYSICIAN, updatedPhysicians);
	};

	const updateHeightAndWeight = async ({ userData, key, data }) =>
		new Promise((resolve, reject) => {
			const opList = [
				{
					operation: 'ADD',
					path: `/Aggregatedetails/${userData?.profileId}/${key}`,
					value: data,
				},
			];
			setUser({ ...userData, [key]: data });
			updateUserInfoInStore({ changes: { [key]: data } });
			patchUserInfo(opList)
				.then(() => {
					const profile = {
						...profiles[userData?.profileId],
						weight: userData.weight,
						height: userData.height,
						[key]: data,
					};
					const updatedProfiles = { ...profiles, [userData?.profileId]: profile };
					setProfiles(updatedProfiles);
					resolve();
				})
				.catch(() => reject());
		});

	const updateUserOnlineStatus = async status =>
		new Promise((resolve, reject) => {
			const opList = [{ operation: 'ADD', path: '/isOnline', value: status }];
			patchUserInfo(opList)
				.then(() => {
					resolve();
				})
				.catch(e => {
					console.error('Error while updating online status', e);
					reject();
				});
		});

	const onUserUpdate = async (inputData, partialZustandIntegrationFlag) => {
		const data = { ...user, ...inputData };
		return new Promise((resolve, reject) => {
			if (!partialZustandIntegrationFlag) {
				setUser(data);
			}

			const firstName = data?.firstName.trim();
			const lastName = data?.lastName.trim();
			const profileId = data?.profileId;
			const changedFields = getChangedFieldsOnEditProfile(inputData, formData, user);

			const { firstObject, secondObject } = divideObject(changedFields);

			const opList1 = [];
			const opList2 = [];
			for (const key in firstObject) {
				opList1.push({
					operation: 'ADD',
					path: `/Aggregatedetails/${profileId}/${key}`,
					value: firstObject[key],
				});
			}

			for (const key in secondObject) {
				opList2.push({
					operation: 'ADD',
					path: `/Aggregatedetails/${profileId}/${key}`,
					value: secondObject[key],
				});
			}

			patchUserInfo(opList1)
				.then(updatedUser => {
					updatedUser.value.address = changedFields.address;

					updateUserOnNameChange({ firstName, lastName, updatedUser });

					resolve();
				})
				.catch(() => {
					setToastMsg(t(`Please enter correct details!`));
				});

			if (opList2.length) {
				patchUserInfo(opList2)
					.then(updatedUser => {
						updateUserOnNameChange({ firstName, lastName, updatedUser });

						resolve();
					})
					.catch(() => {
						setToastMsg(t(`Please enter correct details!`));
					});
			}
		});
	};

	const userUpdateNotification = async (field, value, data) => {
		const profileId = data?.profileId;
		const addressArray = ['country', 'state', 'city', 'zip', 'addressLine1'];
		const changeField = addressArray.includes(field) ? 'address' : field;
		let changeValue = value;
		if (changeField === 'address') {
			if (typeof profiles[profileId].address === 'string') {
				changeValue = [
					{
						addressLine1: profiles[profileId].address,
						addressLine2: profiles[profileId].addressLine2,
						city: profiles[profileId].city,
						country: profiles[profileId].country,
						state: profiles[profileId].state,
						zip: profiles[profileId].zip,
					},
				];
			} else changeValue = profiles[profileId].address || [{}];
			changeValue[0][field == 'addressLine1' ? 'address' : field] = value;
		}
		if (changeField === 'gender') changeValue = value ? value.toUpperCase() : value;
		return new Promise((resolve, reject) => {
			updateUserInfoInStore({ changes: data });
			setUser(data);

			// This is now an object of objects
			// /Aggregatedetails/*The hash*/*fieldName*
			const opList = [
				{
					operation: 'ADD',
					path: `/Aggregatedetails/${profileId}/${changeField}`,
					value: changeValue,
				},
			];
			patchUserInfo(opList)
				.then(() => {
					if (field == 'firstName') updateUserOnNameChange({ firstName: value, lastName: data.lastName });
					else if (field == 'lastName')
						updateUserOnNameChange({ firstName: data.firstName, lastName: value });
					else {
						// Update the profiles object here
						const updatedProfiles = { ...profiles, [profileId]: data };
						setProfiles(updatedProfiles);
					}
					resolve();
				})
				.catch(() => reject());
		});
	};

	const onLanguageUpdate = data => {
		i18n.changeLanguage(data);
		setSelectedLanguage(data);
		updateUser(UPDATE_TYPES.LANGUAGE, data);
		setToastMsg(`${t('Language is translated to')} ${LANGUAGE_MAPPING.filter(l => l.code == data)[0].name}`);
		sendAnalyticsEvent(LANGUAGE_CHANGE, { language: data });
	};

	const patchUserInfo = data =>
		new Promise((resolve, reject) => {
			// The URL for Patch user profile is now version 2
			const url = `${__config.data_sources.blume}User?version=3`;
			axios
				.patch(url, data, {
					headers: {
						Accept: '*/*',
						'Content-Type': 'application/json-patch+json',
					},
				})
				.then(res => {
					if (res?.status === 200) {
						resolve(res.data);
					} else reject(false);
				})
				.catch(() => {
					reject(false);
				});
		});

	const updateUserOnNameChange = async ({ firstName, lastName, updatedUser }) => {
		try {
			const userInfo = await getUserInfoAPI.default({ __config });
			const aggregateDetails = userInfo.aggregatedetails;
			setProfiles(aggregateDetails);
			if (updatedUser) {
				updateUserInfoInStore({ changes: updatedUser });
				setUser({ ...updatedUser.value, profileId: updatedUser.key, email: user.email, phone: user.phone });
				calculateLeftAndRightProfiles(aggregateDetails, updatedUser);
			} else {
				for (const key in aggregateDetails) {
					if (
						aggregateDetails[key]?.fieldName === firstName &&
						aggregateDetails[key]?.lastName === lastName
					) {
						onProfileSelection({ ...aggregateDetails[key], profileId: key });
					}
				}
				calculateLeftAndRightProfiles();
			}
		} catch (error) {
			console.error(`Error while getting current loggedIn user: ${error.message}`);
		}
	};

	const calculateLeftAndRightProfiles = useCallback(
		(profilesParam = null, userParam = null) => {
			// Calculate the right and left profiles
			const profilesInner = profilesParam || profiles;
			const userInner = userParam ? { ...userParam.value, profileId: userParam.key } : user;
			if (profilesInner && Object.keys(profilesInner).length > 2) {
				const currentUserIndex = Object.keys(profilesInner)?.findIndex(pId => pId === userInner?.profileId);

				let leftUserIndex;
				let rightUserIndex;

				// Calculate the left profile index
				if (currentUserIndex >= 0 && currentUserIndex <= Object.values(profilesInner)?.length - 1) {
					if (currentUserIndex === 0) {
						// If the current element is first element in the array, the prev item should be the last element in the array
						leftUserIndex = Object.values(profilesInner)?.length - 1;
					} else {
						// Else the prev element will be the previous one form the array
						leftUserIndex = currentUserIndex - 1;
					}

					if (currentUserIndex === Object.values(profilesInner)?.length - 1) {
						// If the current element is the last element in the array, the the next item should be the first one
						rightUserIndex = 0;
					} else {
						// Else the next element will be the next one form the array
						rightUserIndex = currentUserIndex + 1;
					}
				}

				// Get the Profile objects and assign values
				const leftUser = Object.values(profilesInner)[leftUserIndex];
				const rightUser = Object.values(profilesInner)[rightUserIndex];

				// Set State
				setLeftProfile(leftUser);
				setRightProfile(rightUser);
			} else if (profilesInner && Object.keys(profilesInner).length === 2) {
				const currentUserIndex = Object.keys(profilesInner)?.findIndex(pId => pId === userInner?.profileId);
				const rightUserIndex = currentUserIndex === 1 ? 0 : 1;

				const rightUser = Object.values(profilesInner)[rightUserIndex];
				setLeftProfile(undefined);
				setRightProfile(rightUser);
			}
		},
		[profiles, user]
	);

	const getUserAddress = address => {
		if (Array?.isArray(address) && address?.length) {
			return {
				address: address[0]?.addressLine1 || '',
				addressLine2: address[0]?.addressLine2 || '',
				city: address[0]?.city || '',
				country: address[0]?.country || '',
				zip: address[0]?.zip || '',
				state: address[0]?.state || '',
			};
		}
		return {};
	};

	const getMyPhysicianData = profile => {
		const myPhysiciansArray = (profile?.myPhysician || []).map(physician => ({
			id: generateRandomString(16),
			name: physician.name || '',
			specialisation: physician.specialisation || '',
			phone: physician.phone || '',
			email: physician.email || '',
		}));

		return myPhysiciansArray;
	};

	const getEmergencyContactsData = (profile, updated, newVal, toDelete) => {
		let emergencyContactsArray = profile?.emergencyContacts ? [...profile.emergencyContacts] : [];
		emergencyContactsArray = emergencyContactsArray
			.filter(d => d?.emergencyContactId !== toDelete)
			.map(contact => {
				if (updated && updated.emergencyContactId == contact.emergencyContactId)
					return {
						id: updated?.emergencyContactId,
						name: updated?.name || contact.name,
						relation: updated?.relation || updated?.relationship || contact.relation,
						phone: updated?.phone || contact.phone,
						email: updated?.email || contact.email,
						emergencyContactId: updated?.emergencyContactId,
					};
				return contact;
			});

		if (newVal) {
			newVal.id = newVal.emergencyContactId || uuidv4();
			emergencyContactsArray.push(newVal);
		}
		return emergencyContactsArray.filter(
			(contact, index, self) => self.findIndex(c => c.email === contact.email) === index
		);
	};

	// Get current loggedIn user when fully authorized.
	// existingUserId param is used to restore the current user in the profile page instead of updating the primary user
	const getUserInfo = async existingUserId => {
		try {
			storeFetchUser({ __config });
			if (existingUserId) {
				onProfileSelection(userInfo.aggregatedetails[existingUserId]);
			}
		} catch (error) {
			console.error(`Error while getting current loggedIn user: ${error.message}`);
		}
	};

	// Method to update the currently selected profile.
	const onProfileSelection = (selectedProfile, newEmergencyContacts = {}) => {
		let profileId = selectedProfile?.profileId;
		const { add, update, delete: toDelete } = newEmergencyContacts;

		if (!profileId) {
			for (const key in profiles) {
				if (
					profiles[key]?.firstName === selectedProfile?.firstName &&
					profiles[key]?.lastName === selectedProfile?.lastName
				) {
					profileId = key;
				}
			}
		}

		const profile = profiles[profileId];

		const tempEmergencyContacts = getEmergencyContactsData(profile, update, add, toDelete);

		setEmergencyContacts(tempEmergencyContacts);

		setPhysicians(getMyPhysicianData(profile));

		// Set the aggregate details to the primary user
		const updatedUser = {
			img: profile?.profilePictureUrl,
			firstName: profile?.firstName || '',
			middleName: profile?.middleName || '',
			lastName: profile?.lastName || '',
			birthDate: profile?.birthDate || '',
			gender: profile?.gender ? profile?.gender.toUpperCase() : '',
			phone: user?.phone || '',
			email: user?.email || '',
			race: profile?.race || '',
			ethnicity: profile?.ethnicity || '',
			birthSex: profile?.birthSex || '',
			language: profile?.language || '',
			ssn: profile?.ssn || '',
			maritalStatus: profile?.maritalStatus || '',
			smokingStatus: profile?.smokingStatus || '',
			alcoholUse: profile?.alcoholUse || '',
			motherMaidenName: profile?.motherMaidenName || '',
			height: profile?.height || '',
			weight: profile?.weight || '',
			abha: profile?.abha || {},
			profileId,
			isPrimary: profile?.isPrimary,
			address: profile?.address || '',
			addressLine2: profile?.addressLine2 || '',
			city: profile?.city || '',
			country: profile?.country || '',
			zip: profile?.zip || '',
			state: profile?.state || '',
			myPhysician: getMyPhysicianData(profile),
			emergencyContacts: tempEmergencyContacts,
			allergyMapping: getAllergyDataFromProfile(profile?.allergyMapping),
			...getUserAddress(profile?.address),
		};
		updateUserInfoInStore({ changes: updatedUser });
		setUser(updatedUser);
		setAggregateDetails(profile || {});
		return updatedUser;
	};

	return (
		<SettingsViewContext.Provider
			value={{
				aggregateDetails,
				emergencyContacts,
				physicians,
				selectedLanguage,
				user,
				onEmergencyContactCreate,
				onEmergencyContactUpdate,
				onEmergencyContactDelete,
				onReferringPhysicianCreate,
				onReferringPhysicianUpdate,
				onReferringPhysicianDelete,
				onUserUpdate,
				onLanguageUpdate,
				onProfileSelection,
				leftProfile,
				rightProfile,
				userUpdateNotification,
				getUserInfo,
				updateHeightAndWeight,
				triggerDocumentSectionRefresh,
				setTriggerDocumentSectionRefresh,
				primaryUser,
			}}
		>
			{children}
		</SettingsViewContext.Provider>
	);
};

const useSettingsViewContext = () => useContext(SettingsViewContext);

export { SettingsViewContext, SettingsViewContextProvider, useSettingsViewContext };
export default SettingsViewContextProvider;
