import React, { useState, useRef, useEffect } from 'react';
import Lottie from 'lottie-web';
import { useMsal } from '@azure/msal-react';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { InteractionStatus } from '@azure/msal-browser';
import axios from 'axios';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { default as MuiGrid } from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import ArticleOutlined from '@mui/icons-material/ArticleOutlined';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import { IconButton, SvgIcon } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
	useConfig,
	useAuth,
	useAppModeContext,
	useSelector,
	mediaTablet,
	mediaMobile,
	useIsMobile,
	useIsTablet,
} from '@worklist-2/core/src';
import LoginField from '@worklist-2/ui/src/components/LoginField';
import { ReactComponent as RamSoftLogoSvg } from '@worklist-2/ui/src/assets/branding/svg/RS-Logo-Full-Colour.svg';
import { ReactComponent as SmallOmegaAILogoSvg } from '@worklist-2/ui/src/assets/branding/svg/omega-ai_logo small.svg';
import BlumeAvatar from '@worklist-2/ui/src/assets/branding/png/Blume_logo.png';
import OutlineButtonWithIcon from '@worklist-2/ui/src/components/OutlineButtonWithIcon';
import { MainFooter } from '@worklist-2/ui/src/components/Footer/MainFooter';
import NewsModal from '@worklist-2/ui/src/components/News/NewsModal';
import { LoginViewContent } from './LoginViewStyles';
import animation from '@worklist-2/ui/src/assets/animation/web/json1/data.json';
import { RoundedButton } from '@worklist-2/ui/src/components/Login/loginSelectorStyles';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import MobileBottomDrawer from '../../components/MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';
import NewsModalMobile from '@worklist-2/ui/src/components/News/NewsModalMobile';
import { useTranslation } from 'react-i18next';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const LoginBlumeView = () => {
	const { region, language, updateValue } = useSelector();
	const __config = useConfig();
	const { loggedIn, setLoggingIn, updateAccessToken } = useAuth();
	const { updateAppMode, isPatientPortalMode } = useAppModeContext();
	const appBlumeMode = isPatientPortalMode();
	const [errorMessage, setErrorMessage] = useState('');
	const buttonRef = useRef(null);
	const [showNewsModal, toggleNewsModal] = useState(false);
	const [hovered, setHovered] = useState(false);
	const isDisplayPage = useRef(false);
	const { instance, inProgress } = useMsal();
	const { i18n } = useTranslation();
	const { t } = useTranslation('login');
	const [searchParams] = useSearchParams();
	const hashID = searchParams.get('language');
	const location = useLocation();
	const [isDisable, setIsDisable] = useState(false);
	const phoenixBlumeNewSchedulingFlow = useBooleanFlagValue('phoenix-blume-new-scheduling-flow');
	const sprinterOaiLandingpageNews = useBooleanFlagValue('sprinter-oai-landingpage-news');
	const rerenderCount = useRef(0);
	const navigate = useNavigate();

	const onEmailChange = email => {
		setErrorMessage('');
		email.setCustomValidity('');
	};

	const onKeyPress = event => {
		if (event.key === 'Enter') {
			buttonRef.current.click();
			event.preventDefault();
		}
	};

	useEffect(() => {
		// If not loggedIn and has orgID param redirect to scheduling route
		const orgId = searchParams.get('orgId');

		if (!phoenixBlumeNewSchedulingFlow || !orgId || loggedIn) return;

		navigate(`/book-appointment?orgId=${orgId}`);
	}, [loggedIn, navigate, phoenixBlumeNewSchedulingFlow, searchParams]);

	useEffect(() => {
		i18n.changeLanguage(hashID);
		updateValue('language', hashID);
	}, []);

	// if we're here, we're logging in
	useEffect(() => setLoggingIn(true));
	/**
	 * App mode
	 */
	useEffect(() => {
		updateAppMode('patientPortal');

		const animationData = document.querySelector('#AnimationData');
		if (Lottie.loadAnimation) {
			Lottie.loadAnimation({
				container: animationData,
				animationData: animation,
				autoplay: true,
				loop: false,
			});
		}

		const animationDataMobile = document.querySelector('#AnimationDataMobile');

		if (Lottie.loadAnimation) {
			Lottie.loadAnimation({
				container: animationDataMobile,
				animationData: animation,
				autoplay: true,
				loop: false,
			});
		}
		if (error !== null) {
			setErrorMessage(
				error_description.startsWith('AADB2C99001')
					? t('The email address is already registred.')
					: error_description
			);
		}
	}, []);

	/**
	 * For User Invitation flow
	 */

	/**
	 * Renders the navbar component with a sign-in button if a user is not authenticated
	 */
	function useQueryParams() {
		const params = new URLSearchParams(window ? window.location.search : {});

		return new Proxy(params, {
			get(target, prop) {
				return target.get(prop);
			},
		});
	}

	const {
		code,
		error,
		error_description,
		DOB,
		hashId,
		internalManagingOrganizationID,
		studyInstanceUID,
		creatorUserId,
		isUploaded,
		isMobile,
		token,
		oAiAuthToken,
	} = useQueryParams();

	if (code !== null) {
		const tokenRequest = {
			code,
			redirectUri: 'urn:ietf:wg:oauth:2.0:oob',
			scopes: __config.auth.omegaai.scopes,
			codeVerifier: '1234567890123456789012345678901234567890123',
		};
		// acquire a token by exchanging the code
		instance
			.acquireTokenByCode(tokenRequest)
			.then(() => {
				window.location.search = '';
			})
			.catch(e => {
				console.error(e);
				isDisplayPage.current = true;
			});
	} else {
		isDisplayPage.current = true;
	}

	const handleSignup = (msalInstance, signUpInfo = '') => {
		const queryParameters = {
			web: true,
			siteName: 'PP',
			login_uri: __config.auth.omegaai.msal.patient_portal_logout_redirect_uri,
			regionSelected: region,
			languageSelected: language,
			signUpInfo,
		};
		const loginRequest = {
			scopes: __config.auth.omegaai.scopes,
			extraQueryParameters: queryParameters,
			redirectUri: __config.auth.omegaai.msal.patient_portal_redirect_uri,
			prompt: 'select_account',
		};
		if (inProgress === InteractionStatus.None) {
			msalInstance.loginRedirect(loginRequest).catch(e => {
				console.error(e);
				setErrorMessage(e);
			});
		}
	};

	useEffect(() => {
		const currentURL = window.location.pathname;
		console.log('currentURL', currentURL, oAiAuthToken);
		if (currentURL.startsWith('/external-form')) {
			updateAccessToken(`bearer ${token}`);

			axios.interceptors.request.use(
				config => {
					config.headers.authorization = `Bearer ${token}`;
					config.headers.accept = `*/*`;
					return config;
				},
				error => Promise.reject(error)
			);
		}

		if (currentURL.startsWith('/externalImageViewer')) {
			if (oAiAuthToken) {
				updateAccessToken(`bearer ${oAiAuthToken}`);
				axios.interceptors.request.use(
					config => {
						config.headers.authorization = `Bearer ${oAiAuthToken}`;
						config.headers.accept = `*/*`;
						config.headers['ramsoft-internalmanagingorganizationid'] = internalManagingOrganizationID;
						config.headers.StudyInstanceUID = studyInstanceUID;
						return config;
					},
					error => Promise.reject(error)
				);

				const viewUrl = `/externalImageViewer/?internalManagingOrganizationID=${internalManagingOrganizationID}&studyInstanceUID=${studyInstanceUID}&creatorUserId=${creatorUserId}&isUploaded=${isUploaded}&isMobile=${isMobile}&oAiAuthToken=${oAiAuthToken}`;
				navigate(viewUrl);
			} else {
				console.log("Mobile Viewer URL doesn't have oAiAuthToken");
			}
		} else {
			console.log("URL path does not start with '/external'");
		}
	}, []);

	const handleLogin = (event, msalInstance, email) => {
		setIsDisable(true);
		setErrorMessage('');
		email.setCustomValidity('');
		if (!email.value) {
			setErrorMessage('Please enter your email address');
			event.preventDefault();
			setIsDisable(false);
		} else if (!email.validity.valid) {
			setErrorMessage(email.validationMessage);
			event.preventDefault();
			setIsDisable(false);
		} else {
			sessionStorage.setItem('login_hint', email.value);
			const url = `${__config.data_sources.blume}User/validate/${email.value}`;

			axios
				.get(url)
				.then(response => {
					if (response.data.domainhint) {
						const queryParameters = {
							login_hint: email.value,
							domain_hint: response.data.domainhint,
							siteName: 'PP',
							regionSelected: region,
							languageSelected: language,
							deviceId: localStorage.getItem(`${email.value.toLocaleLowerCase()}_deviceId`),
							userAgent: encodeURIComponent(navigator.userAgent),
						};

						if (
							__config.data_dog &&
							__config.data_dog.applicationId &&
							!__config.data_dog.applicationId.includes('DATADOG_APPLICATION_ID')
						) {
							queryParameters.ddappid = __config.data_dog.applicationId;
							queryParameters.ddt = __config.data_dog.clientToken;
							queryParameters.ddenv = __config.data_dog.env;
							queryParameters.ddsite = __config.data_dog.site;
						}

						const loginRequest = {
							scopes: __config.auth.omegaai.scopes,
							redirectUri: __config.auth.omegaai.msal.patient_portal_redirect_uri,
						};

						const kmsi = localStorage.getItem('kmsi');
						if (kmsi && kmsi.toUpperCase() == email.value.toUpperCase()) {
							// KMSI enabled for this user
							queryParameters.iskmsi = true;
						} else {
							loginRequest.prompt = 'select_account';
						}

						loginRequest.extraQueryParameters = queryParameters;

						if (inProgress === InteractionStatus.None) {
							msalInstance.loginRedirect(loginRequest).catch(e => {
								console.error(e);
								setErrorMessage(e);
								setIsDisable(false);
							});
						}
					} else {
						setIsDisable(false);
						throw new Error('Invalid domainHint, please try again!');
					}
				})
				.catch(e => {
					handleSignup(instance);
				});
		}
	};

	const handleAutoLogin = (msalInstance, email, userInfo = '') => {
		// Clear all session storage data to avoid intraction error
		sessionStorage.clear();

		sessionStorage.setItem('login_hint', email);

		rerenderCount.current += 1;

		const url = `${__config.data_sources.blume}User/validate/${email}`;
		axios
			.get(url)
			.then(response => {
				if (response.data.domainhint) {
					const queryParameters = {
						login_hint: email,
						domain_hint: response.data.domainhint,
						siteName: 'PP',
						regionSelected: region,
						languageSelected: language,
						deviceId: localStorage.getItem(`${email.toLocaleLowerCase()}_deviceId`),
						userAgent: encodeURIComponent(navigator.userAgent),
					};
					if (
						__config.data_dog &&
						__config.data_dog.applicationId &&
						!__config.data_dog.applicationId.includes('DATADOG_APPLICATION_ID')
					) {
						queryParameters.ddappid = __config.data_dog.applicationId;
						queryParameters.ddt = __config.data_dog.clientToken;
						queryParameters.ddenv = __config.data_dog.env;
						queryParameters.ddsite = __config.data_dog.site;
					}

					const loginRequest = {
						scopes: __config.auth.omegaai.scopes,
						redirectUri: __config.auth.omegaai.msal.patient_portal_redirect_uri,
					};

					const kmsi = localStorage.getItem('kmsi');
					if (kmsi && kmsi.toUpperCase() === email.toUpperCase()) {
						// KMSI enabled for this user
						queryParameters.iskmsi = true;
					} else {
						loginRequest.prompt = 'select_account';
					}

					loginRequest.extraQueryParameters = queryParameters;

					if (inProgress === InteractionStatus.None) {
						msalInstance.loginRedirect(loginRequest).catch(e => {
							// If the error is not interaction_in_progress, set the error message
							// https://stackoverflow.com/questions/66405214/browserautherror-interaction-in-progress-interaction-is-currently-in-progress
							if (e.errorCode?.toString() == 'interaction_in_progress') {
								console.warn(`Render count: ${rerenderCount.current}, exception: ${e}`);
							} else {
								setErrorMessage(e);
								console.error(`Render count: ${rerenderCount.current}, exception: ${e}`);
							}
							setIsDisable(false);
						});
					}
				} else {
					setIsDisable(false);
					throw new Error('Invalid domainHint, please try again!');
				}
			})
			.catch(e => {
				handleSignup(instance, userInfo);
			});
	};

	// redirect to b2c signup page if the route is /signup. To be used for blume invites
	useEffect(() => {
		if (
			(location.pathname === '/signup' || location.pathname.includes('/studyDetail')) &&
			!loggedIn &&
			appBlumeMode
		) {
			const searchUrl = location.search;
			if (searchUrl.includes('?')) {
				const decodedData = searchUrl.split('?');
				if (decodedData[1]) {
					let queryParams = window.atob(decodedData[1]);
					queryParams = new URLSearchParams(queryParams);
					const dob = queryParams.get('dob');
					const fName = queryParams.get('fName');
					const lName = queryParams.get('lName');
					const email = queryParams.get('email');
					if (location.pathname === '/signup') {
						handleSignup(instance, JSON.stringify({ dob, fName, lName, email }));
					} else if (location.pathname.includes('/studyDetail')) {
						handleAutoLogin(instance, email, JSON.stringify({ fName, lName, email }));
					}
				} else {
					handleSignup(instance);
				}
			} else {
				handleSignup(instance);
			}
		}
	}, [location, appBlumeMode]);

	const isTablet = useIsTablet();
	const isMobileView = useIsMobile();

	if (window.location.pathname.startsWith('/externalImageViewer')) {
		return null;
	}

	return isDisplayPage.current && location.pathname != '/signup' ? (
		<>
			<AppBar
				data-testid="login-blume-view"
				sx={{
					backgroundColor: 'transparent',
					boxShadow: 'none',
					paddingLeft: '50px',
					paddingRight: '30px',
					height: '78px',
					[mediaTablet]: {
						padding: '23px 16px',
						boxSizing: 'border-box',
						height: 'unset',
					},
				}}
			>
				<Toolbar
					sx={{
						padding: '0px',
						height: '100%',
						paddingLeft: '0px !important',
						paddingRight: '0px !important',
						[mediaTablet]: {
							minHeight: 'unset',
						},
					}}
				>
					<Box
						sx={{
							flexGrow: 1,
							height: '100%',
						}}
					>
						{isTablet ? (
							<RamSoftLogoSvg
								data-testid="RamsoftLogo"
								height="100%"
								style={{
									cursor: 'pointer',
									height: '35px',
									marginTop: '0px',
								}}
								onClick={() => window.open('https://www.ramsoft.com/', '_blank')}
							/>
						) : (
							<RamSoftLogoSvg
								data-testid="RamSoftLogoSvg"
								height="100%"
								style={{
									cursor: 'pointer',
									height: '38px',
									marginTop: '38px',
								}}
								onClick={() => window.open('https://www.ramsoft.com/', '_blank')}
							/>
						)}
					</Box>

					<Box
						sx={{
							'& > :not(style) + :not(style)': {
								ml: '20px',
							},
							opacity: '0.87',
						}}
					>
						{!sprinterOaiLandingpageNews && (
							<OutlineButtonWithIcon
								icon={<ArticleOutlined />}
								text={t('NEWS')}
								onClick={() => toggleNewsModal(true)}
							/>
						)}
					</Box>
				</Toolbar>
			</AppBar>
			<MuiGrid
				container
				columns={100}
				spacing={0}
				style={{
					background: 'linear-gradient(268.97deg, #DDEAF8 3.59%, #EFF5FC 32.98%, #FFFFFF 93.74%)',
				}}
			>
				<LoginViewContent>
					<MuiGrid
						item
						id="Animation"
						sx={{
							height: '100vh',
							position: 'relative',
						}}
						xs={90}
					>
						<Box
							id="AnimationData"
							sx={{
								display: 'flex',
								flexDirection: 'column',
								position: 'absolute',
								left: '0',
								bottom: '50px',
								width: '100%',
							}}
						/>
					</MuiGrid>
					<MuiGrid
						id="PatientLoginForm"
						sx={{
							width: '55%',
							height: '100vh',
							position: 'fixed',
							marginLeft: '45%',
							[mediaTablet]: {
								background: 'linear-gradient(360deg, #DDEAF8 20.99%, #FFFFFF 100%) !important',
							},
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100vh',
								width: '100%',
								maxWidth: '586px',
								margin: 'auto',
								[mediaTablet]: {
									justifyContent: 'end',
								},
							}}
						>
							<MuiGrid
								item
								id="Animation"
								sx={{
									height: '100vh',
									position: 'relative',
									display: 'none',
									flexDirection: 'column',
									justifyContent: 'end',
									'& #AnimationData > svg': {
										height: '448px',
									},
									[mediaTablet]: {
										display: 'flex !important',
										height: 'unset !important',
									},
								}}
							>
								<Box
									id="AnimationDataMobile"
									sx={{
										display: 'flex',
										flexDirection: 'column',
										position: 'relative',
										[mediaTablet]: {
											left: '-7%',
											width: '175%',
											top: '0%',
										},
										[mediaMobile]: {
											left: '0%',
											width: '155%',
											top: '10%',
										},
									}}
								/>
							</MuiGrid>

							<Box
								id="FormWrapper"
								sx={{
									display: 'inline-flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'center',
									height: 'fit-content',
									borderRadius: '20px',
									width: '100%',
									maxWidth: '586px',
									margin: 'auto',
									[mediaTablet]: {
										justifyContent: 'unset',
										margin: '0 0 100px 0',
										zIndex: '1',
										backgroundImage:
											'radial-gradient(100px at 50% 0px, transparent 50px, white 0px) !important',
										backgroundColor: 'unset',
									},
									[mediaMobile]: {
										margin: '0 0 30px 0',
										paddingBottom: '90px',
										borderBottomLeftRadius: '0',
										borderBottomRightRadius: '0',
										backgroundImage:
											'radial-gradient(100px at 50% 0px, transparent 34px, white 0px) !important',
									},
								}}
							>
								{!isMobileView && !isTablet && (
									<RoundedButton
										alt
										data-testid="GotoDual"
										sx={{
											mt: '0px',
										}}
										onClick={() => (window.location.href = __config.omegaai_url)}
										onMouseEnter={() => setHovered(true)}
										onMouseLeave={() => setHovered(false)}
									>
										{hovered && <span>{t('GO BACK')}</span>}
										<ArrowBackOutlinedIcon
											sx={{
												fontSize: 25,
												color: '#42a5f5',
											}}
										/>
									</RoundedButton>
								)}
								<Box
									validate
									component="form"
									sx={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										marginLeft: '-33px',
										marginBottom: '70px',
										height: 'fit-content',
										width: '100%',
										[mediaTablet]: {
											margin: '0',
											padding: '0 17px 40px 17px',
										},
									}}
								>
									<Box
										validate
										sx={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											height: 'fit-content',
											[mediaTablet]: {
												background: '#FFFFFF',
												boxShadow: '4px 1px 6px rgba(0, 0, 0, 0.07)',
												padding: '8px',
												borderRadius: '50%',
												position: 'relative',
												marginTop: '-38px',
												'& img': {
													marginTop: '0 !important',
													width: '58px !important',
												},
											},
											[mediaMobile]: {
												marginTop: '-28px',
												'& img': {
													width: '36px !important',
												},
											},
										}}
									>
										<img
											src={BlumeAvatar}
											style={{
												marginTop: '70px',
												width: '85px',
											}}
										/>
									</Box>
									<Box
										data-testid="GotoOmegaAI"
										sx={{
											margin: '0 0 0 -80%',
											height: '32.47px',
											background:
												'linear-gradient(95.84deg, #FFFFFF 15.11%, rgba(231, 231, 231, 0) 93.69%, rgba(255, 255, 255, 0) 93.7%)',
											boxShadow: 'inset 1px 1px 2px rgba(0, 0, 0, 0.08)',
											borderRadius: '34px',
											display: isTablet ? 'flex' : 'none',
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'center',
											[mediaMobile]: {
												margin: '0 0 0 -80%',
											},
										}}
										onClick={() => (window.location.href = __config.omegaai_url)}
									>
										<IconButton
											sx={{
												width: '33px',
												height: '33px',
											}}
										>
											<SvgIcon inheritViewBox component={SmallOmegaAILogoSvg} />
										</IconButton>
										<IconButton
											sx={{
												width: '33px',
												height: '33px',
											}}
										>
											<ArrowBackIcon sx={{ color: '#42A5F5' }} />
										</IconButton>
									</Box>
									<Box
										sx={{
											width: '100%',
											display: 'flex',
											justifyContent: 'center',
											alignContent: 'center',
											flexWrap: 'wrap',
											[mediaTablet]: {
												flexDirection: 'column',
												alignItems: 'center',
											},
											[mediaMobile]: {
												flexDirection: 'column',
												alignItems: 'center',
											},
										}}
									>
										<Box
											sx={{
												width: '100%',
												alignItems: 'start',
												marginTop: '19px',
												marginLeft: '15%',
												display: 'grid',
												[mediaTablet]: {
													marginLeft: '25%',
												},
												[mediaMobile]: {
													marginLeft: 'unset',
												},
											}}
										>
											<FormLabel
												sx={{
													fontSize: '34px',
													lineHeight: '56px',
													letterSpacing: '-0.5px',
													color: '#121212',
													opacity: '0.87',
													[mediaMobile]: {
														fontSize: '24px',
														lineHeight: '28px',
													},
												}}
											>
												{t('Welcome to Blume')}
											</FormLabel>
											<FormLabel
												sx={{
													marginTop: '6px',
													fontSize: '20px',
													letterSpacing: '0.18px',
													lineHeight: '24px',
													color: 'rgba(18, 18, 18, 0.6)',
													opacity: '0.87',
													[mediaTablet]: {
														fontSize: '18px',
														lineHeight: '24px',
													},
													[mediaMobile]: {
														fontSize: '14px',
														lineHeight: '16px',
														display: 'flex',
														'flex-direction': 'column',
													},
												}}
											>
												{t('Please enter your email address')}
											</FormLabel>
										</Box>
										<Box
											sx={{
												marginTop: '29px',
												minHeight: '81px',
												[mediaMobile]: {
													width: '100%',
												},
											}}
										>
											<LoginField
												autoFocus
												visible
												width={isMobileView ? 320 : 404}
												onChange={() => onEmailChange(emailLogin)}
												onKeyPress={onKeyPress}
											/>

											<FormLabel
												data-cy="labelerror_lgp1"
												id="loginError"
												style={{
													color: '#CF6679',
													maxWidth: '400px',
													fontSize: '12px',
													paddingLeft: '7px',
													display: !errorMessage ? 'none' : 'block',
													lineHeight: '16px',
													letterSpacing: '0.4px',
												}}
											>
												{t(errorMessage)}
											</FormLabel>
										</Box>

										<Button
											ref={buttonRef}
											fullWidth
											color="rsPrimary"
											disabled={isDisable}
											id="loginButton"
											sx={{
												width: '100%',
												maxWidth: '404px',
												height: '56px',
												mt: '1px',
												borderRadius: '6px',
												fontSize: '14px',
												lineHeight: '16px',
												fontWeight: '500',
												letterSpacing: '1.25px',
												color: '#FFFFFFD9',
												[mediaMobile]: {
													width: '100% !important',
													maxWidth: '100% !important',
													height: '48px',
												},
												'&.Mui-disabled': {
													background: '#42a5f594',
													color: '#FFFFFFD9',
												},
											}}
											type="button"
											variant="contained"
											onClick={event => handleLogin(event, instance, emailLogin)}
										>
											{t('Continue')}
										</Button>

										<Box
											sx={{
												marginTop: '10px',
											}}
										>
											<FormLabel
												sx={{
													fontSize: '16px',
													lineHeight: '19px',
													color: 'rgba(18, 18, 18, 0.6)',
													display: 'inline',
													[mediaMobile]: {
														fontSize: '12px',
														lineHeight: '14px',
													},
												}}
											>
												{t('Don’t have an account?')}&ensp;
												<Link
													id="BlumeSignup"
													sx={{
														color: '#42A5F5',
														textDecoration: 'underline',
														opacity: '1',
														cursor: 'pointer',
													}}
													onClick={event => handleSignup(instance)}
												>
													{t('Create an account')}
												</Link>
											</FormLabel>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
					</MuiGrid>
				</LoginViewContent>
			</MuiGrid>
			{/* news modal */}
			{isMobileView ? (
				<MobileBottomDrawer fullHeight open={showNewsModal} onClose={() => toggleNewsModal(false)}>
					<NewsModalMobile isPatientView />
				</MobileBottomDrawer>
			) : (
				<NewsModal isPatientView open={showNewsModal} onClose={() => toggleNewsModal(false)} />
			)}
			{/* footer */}
			<Box
				sx={{
					[mediaMobile]: {
						'& footer': {
							backgroundColor: '#fff !important',
						},
					},
				}}
			>
				<MainFooter
					loginMode
					linkStyle={{
						color: '#121212',
						opacity: '.87',
					}}
				/>
			</Box>
		</>
	) : null;
};

export default LoginBlumeView;
