import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import { KeyboardDoubleArrowLeft } from '@mui/icons-material';

import { useIsTablet } from '@worklist-2/core/src';
import calendarIcon from '@worklist-2/ui/src/assets/img/homepage/calendar.svg';

import { useFormStore } from '../../../stores';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const RenderFormsList = ({
	dateStart,
	setCloseFormsList,
	closeFormsList,
	pendingForms,
	completedForms,
	setOpenDrawer,
	type,
	hidePendingForms,
}) => {
	const selectedForm = useFormStore(state => state.selectedForm);
	const setSelectedForm = useFormStore(state => state.setSelectedForm);

	const phoenixBlumeViewFormsInFullScreen = useBooleanFlagValue('phoenix-blume-view-forms-in-full-screen');
	const { t } = useTranslation('appointments');
	const isTablet = useIsTablet();

	return (
		<Box>
			<Box
				sx={{
					color: '#697586',
					borderBottom: '1px solid #EAECF0',
					padding: '23px 0 14px 0',
					overflow: 'hidden',
				}}
			>
				<Box alignItems="center" display="flex">
					<Box alignItems="center" display="flex" gap="8px">
						<svg fill="none" height="20" viewBox="0 0 18 20" width="18">
							<path
								d="M9.66683 9.16675H4.66683M6.3335 12.5001H4.66683M11.3335 5.83341H4.66683M14.6668 8.75008V5.66675C14.6668 4.26662 14.6668 3.56655 14.3943 3.03177C14.1547 2.56137 13.7722 2.17892 13.3018 1.93923C12.767 1.66675 12.067 1.66675 10.6668 1.66675H5.3335C3.93336 1.66675 3.2333 1.66675 2.69852 1.93923C2.22811 2.17892 1.84566 2.56137 1.60598 3.03177C1.3335 3.56655 1.3335 4.26662 1.3335 5.66675V14.3334C1.3335 15.7335 1.3335 16.4336 1.60598 16.9684C1.84566 17.4388 2.22811 17.8212 2.69852 18.0609C3.2333 18.3334 3.93336 18.3334 5.3335 18.3334H7.5835M16.3335 18.3334L15.0835 17.0834M15.9168 15.0001C15.9168 16.6109 14.611 17.9167 13.0002 17.9167C11.3893 17.9167 10.0835 16.6109 10.0835 15.0001C10.0835 13.3893 11.3893 12.0834 13.0002 12.0834C14.611 12.0834 15.9168 13.3893 15.9168 15.0001Z"
								stroke="#697586"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="1.67"
							/>
						</svg>
						<Typography
							noWrap
							fontSize="14px"
							fontWeight={500}
							lineHeight="20px"
							sx={{ maxWidth: { xs: '18ch', lg: '20ch' } }}
						>
							{t(type)}
						</Typography>
					</Box>
					{moment(dateStart).isValid() && (
						<>
							<Box
								noWrap
								sx={{
									background: '#D0D5DD',
									height: 6,
									width: 6,
									borderRadius: 99,
									margin: '0 14px',
								}}
							/>
							<Box alignItems="center" display="flex" gap="8px">
								<Icon component={calendarIcon} sx={{ height: '20px', width: '19px' }} />
								<Typography noWrap fontSize="14px" fontWeight={500} lineHeight="20px" mt={0.1}>
									{moment(dateStart).format('DD MMM YYYY, hh:mm a')}
								</Typography>
							</Box>
						</>
					)}
				</Box>
				{phoenixBlumeViewFormsInFullScreen && !isTablet && (
					<Box>
						<IconButton
							className="collapse-icon"
							data-testid="KeyboardDoubleArrowLeft"
							sx={{
								height: '30px',
								width: '30px',
								background: '#dadada',
								position: 'fixed',
								zIndex: '99',
								left: 'calc(90vw - 58vw)',
								top: '85px',
								backgroundColor: '#EBF5FE',
								color: 'var(--color-primary)',
								visibility: !closeFormsList ? 'visible' : 'hidden',
							}}
							onClick={() => setCloseFormsList(true)}
						>
							<KeyboardDoubleArrowLeft fontSize="small" />
						</IconButton>
					</Box>
				)}
			</Box>
			{/* Body */}
			<Box display="flex" flexDirection="column" gap="36px" sx={{ padding: '20px 22px 0 0' }}>
				{!hidePendingForms && pendingForms.length ? (
					<Box
						sx={{
							borderRadius: '12px',
							border: '1px solid #EAECF0',
							padding: '16px',
						}}
					>
						<Box alignItems="center" display="flex" gap="8px" sx={{ margin: '0 0 10px 0' }}>
							<Box
								sx={{
									height: '28px',
									width: '28px',
									background: '#FEE4E2',
									borderRadius: '97px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<svg fill="none" height="14" viewBox="0 0 14 14" width="14">
									<path
										d="M7.00018 5.24989V7.58322M7.00018 9.91656H7.00602M6.19245 2.27006L1.3946 10.5573C1.12848 11.0169 0.995422 11.2467 1.01509 11.4354C1.03224 11.5999 1.11844 11.7494 1.25223 11.8467C1.40562 11.9582 1.67119 11.9582 2.20233 11.9582H11.798C12.3292 11.9582 12.5947 11.9582 12.7481 11.8467C12.8819 11.7494 12.9681 11.5999 12.9853 11.4354C13.0049 11.2467 12.8719 11.0169 12.6058 10.5573L7.80791 2.27006C7.54275 1.81205 7.41017 1.58304 7.23719 1.50613C7.08631 1.43904 6.91406 1.43904 6.76317 1.50613C6.5902 1.58304 6.45762 1.81205 6.19245 2.27006Z"
										stroke="#D92D20"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="1.28333"
									/>
								</svg>
							</Box>
							<Typography
								data-testid="pending-forms"
								fontSize="16px"
								fontWeight={500}
								lineHeight="24px"
								sx={{ color: '#4B5565', cursor: 'default' }}
							>
								{t('Pending forms')}
							</Typography>
						</Box>
						{pendingForms.map(form => (
							<List key={form.id}>
								<ListItem
									data-testid="list-item"
									secondaryAction={
										<Button
											ariaLabel="view"
											data-testid="Fill-it"
											edge="end"
											endIcon={<ArrowForwardIosIcon height={7.41} width={12} />}
											sx={{
												color: '#42A5F5',
												textTransform: 'none',
												padding: '5px',
												fontWeight: '600',
												fontSize: '14px',
												lineHeight: '20px',
											}}
										>
											{t('Fill it')}
										</Button>
									}
									sx={{
										background: '#F9FAFB',
										padding: '16px',
										borderRadius: '8px',
										cursor: 'pointer',
										border: form?.id === selectedForm?.id ? '1px solid #42A5F5' : '',
									}}
									onClick={() => {
										setSelectedForm(form);
										setOpenDrawer(true);
									}}
								>
									<ListItemText
										primary={`${form.title}`}
										sx={{
											'& span': {
												maxWidth: 'calc(100% - 75px);',
												overflow: 'hidden',
												whiteSpace: 'nowrap',
												textOverflow: 'ellipsis',
												color: '#101828',
											},
										}}
									/>
								</ListItem>
							</List>
						))}
					</Box>
				) : null}
				{completedForms.length ? (
					<Box
						sx={{
							borderRadius: '12px',
							border: '1px solid #EAECF0',
							padding: '16px',
						}}
					>
						<Box alignItems="center" display="flex" gap="8px" sx={{ margin: '0 0 10px 0' }}>
							<Box
								sx={{
									height: '28px',
									width: '28px',
									background: '#DCFAE6',
									borderRadius: '97px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<svg fill="none" height="9" viewBox="0 0 12 9" width="12">
									<path
										d="M10.6663 1.5L4.24967 7.91667L1.33301 5"
										stroke="#079455"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="1.28333"
									/>
								</svg>
							</Box>
							<Typography
								data-testid="completed-forms"
								fontSize="16px"
								fontWeight={500}
								lineHeight="24px"
								sx={{ color: '#4B5565', cursor: 'default' }}
							>
								{t('Completed forms')}
							</Typography>
						</Box>
						{completedForms.map(form => (
							<List key={form.id}>
								<ListItem
									secondaryAction={
										<Button
											ariaLabel="view"
											data-testid="View form"
											edge="end"
											endIcon={<ArrowForwardIosIcon height={7.41} width={12} />}
											sx={{
												color: '#42A5F5',
												textTransform: 'none',
												padding: '5px',
												fontWeight: '600',
												fontSize: '14px',
												lineHeight: '20px',
											}}
										>
											{t('View')}
										</Button>
									}
									sx={{
										background: '#F9FAFB',
										padding: '16px',
										borderRadius: '8px',
										cursor: 'pointer',
										border: form?.id === selectedForm?.id ? '1px solid #42A5F5' : '',
									}}
									onClick={() => {
										setSelectedForm(form);
										setOpenDrawer(true);
									}}
								>
									<Box sx={{ display: 'flex', flexDirection: 'column' }}>
										<ListItemText
											primary={form.title}
											sx={{
												'& span': {
													maxWidth: 'min(70%,320px)',
													overflow: 'hidden',
													whiteSpace: 'nowrap',
													textOverflow: 'ellipsis',
													color: '#101828',
												},
											}}
										/>
										<Typography
											sx={{
												fontSize: '14px',
												lineHeight: '20px',
												fontWeight: 500,
												color: '#475467',
											}}
										>
											{moment(form.resource?.lastUpdated).format('DD MMMM, YYYY')}
										</Typography>
									</Box>
								</ListItem>
							</List>
						))}
					</Box>
				) : null}
			</Box>
		</Box>
	);
};

export default React.memo(RenderFormsList);
