import getAvailableTimeslots from '@worklist-2/patientPortal/src/api/getAvailableTimeslots';
import moment from 'moment';
import momentTz from 'moment-timezone';
import axios from 'axios';
import { scanTypes, createAppointmentWithHealthcareInfo } from '../V2/utils';
import { fhirExtensionUrls } from '@rs-core/fhir/extension/fhirExtensionUrls';
import getExtensionValueString from '@rs-core/fhir/resource/columnMapping/utils/getExtensionValueString';
import { notificationType } from './consts/consts';
import sendAnalyticsEvent from '@worklist-2/patientPortal/src/analytics';
import {
	APPOINTMENT_RESCHEDULE_SUCCESS,
	APPOINTMENT_SUCCESS,
	UPLOAD_DOCUMENT,
	UPLOAD_DOCUMENT_FAILURE,
} from '@worklist-2/patientPortal/src/analytics/eventTypes';
import { norAcceptedRejected } from '../../Notifications/OaiPatientSyncNotification';
import addAttachments from '@worklist-2/patientPortal/src/api/addAttachments';
import { ALLOWED_DOCUMENT_MIME_TYPES, ALLOWED_DOCUMENT_VIDEO_MIME_TYPES } from '@worklist-2/patientPortal/src/consts';
import { clacDistance } from '../../../stores/schedule';

export const getTimeFromMins = mins => {
	const h = (mins / 60) | 0;
	const m = mins % 60 | 0;

	return momentTz.utc().hours(h).minutes(m).format('hh:mm A');
};

export const getSlots = async (
	selectedDate,
	setSlotsLoading,
	scanType,
	facility,
	setAvailableTimeSlots,
	__config,
	isUpdateForm,
	form,
	appointment
) => {
	let selectedScanType = {};
	let selectedFacility = {};
	if (!selectedDate) return;
	setSlotsLoading(true);
	if (isUpdateForm) {
		if (!scanType) {
			selectedScanType = scanTypes.filter(item => item.Name == appointment?.type)[0];
			form.setValue('scanType', selectedScanType);
		} else {
			selectedScanType = scanType;
		}

		if (!facility) {
			const appointmentHealthCareServiceId = [
				appointment?.resource?.participant
					?.find(item => item?.actor?.reference?.toLowerCase().includes('healthcareservice'))
					?.actor.reference.split('/')[1],
			];
			if (appointmentHealthCareServiceId) {
				const response = await axios.get(
					`${__config.data_sources.blume}Appointment/healthcareService?id=${appointmentHealthCareServiceId}&multipleIds=true`
				);
				if (response?.data?.entry) {
					const facilityIANATimeZone = getExtensionValueString(
						response?.data?.entry[0]?.resource?.providedBy,
						fhirExtensionUrls.organization.ianaTimezone
					);
					selectedFacility = {
						organizationName: appointment?.organization,
						internalOrganizationID: appointment?.internalOrganizationID,
						ianaTimezone: facilityIANATimeZone,
					};
					form.setValue('facility', selectedFacility);
				}
			}
		} else {
			selectedFacility = facility;
		}
	} else {
		selectedScanType = scanType;
		selectedFacility = facility;
	}
	const result = await getAvailableTimeslots({
		__config,
		date: moment(selectedDate).format('YYYY-MM-DD'),
		modality: selectedScanType?.DicomModalities?.toString(),
		orgId: selectedFacility?.internalOrganizationID,
	});
	const timeSlots = [];
	let currentSlot = 1;
	(result || [])?.forEach(eachSlot => {
		const timeSlot = {
			...eachSlot,
			id: currentSlot++,
			selected: false,
			time: `${getTimeFromMins(eachSlot.start)} - ${getTimeFromMins(eachSlot.end)}`,
		};
		timeSlots.push(timeSlot);
	});
	setAvailableTimeSlots(timeSlots);
	setSlotsLoading(false);
};

export const validatePayload = (
	selectedDate,
	patient,
	facility,
	selectedSlot,
	scanType,
	form,
	isUpdateForm,
	appointment
) => {
	const selectedDay = moment(selectedDate).format('YYYY-MM-DD');
	const startDateTime = `${selectedDay} ${new Date(selectedSlot[0].start * 60 * 1000)
		.toISOString()
		.substring(11, 19)}`;
	const endDateTime = `${selectedDay} ${new Date(selectedSlot[0].end * 60 * 1000).toISOString().substring(11, 19)}`;

	const startDateTimeDateObj = new Date(startDateTime);
	const endDateTimeDateObj = new Date(endDateTime);

	let organizationTimeZone;

	if (facility?.ianaTimezone !== Intl.DateTimeFormat().resolvedOptions().timeZone) {
		organizationTimeZone = facility?.ianaTimezone;
	}

	const startTimeInOrgTimeZone = organizationTimeZone
		? momentTz(startDateTimeDateObj).tz(organizationTimeZone, true)
		: startDateTimeDateObj;
	const endTimeInOrgTimeZone = organizationTimeZone
		? momentTz(endDateTimeDateObj).tz(organizationTimeZone, true)
		: endDateTimeDateObj;

	let submitData;

	if (isUpdateForm) {
		let updatedResourceObj = appointment?.resource.extension;
		if (updatedResourceObj.find(item => item?.url.includes('blumeNotes'))) {
			updatedResourceObj = updatedResourceObj?.map(item =>
				item.url.includes('blumeNotes')
					? {
							url: 'http://www.ramsoft.com/fhir/StructureDefinition/appointment/blumeNotes',
							valueString: form.getValues('notes'),
					  }
					: item
			);
		} else {
			updatedResourceObj.push({
				url: 'http://www.ramsoft.com/fhir/StructureDefinition/appointment/blumeNotes',
				valueString: form.getValues('notes'),
			});
		}
		submitData = {
			...appointment?.resource,
			start: new momentTz(startTimeInOrgTimeZone).utc(),
			end: new momentTz(endTimeInOrgTimeZone).utc(),
			extension: updatedResourceObj,
			status: 'proposed',
		};
	} else {
		const patientName = [patient.lastName, patient.firstName];
		if (patient.middleName) {
			patientName.splice(1, 0, patient.middleName);
		}
		submitData = {
			patientName: patientName.join(' '),
			internalOrganizationId: facility.internalOrganizationID,
			internalHealthcareServiceId: selectedSlot[0].healthcareServices[0],
			blumeDescription: form.getValues('notes'),
			start: new momentTz(startTimeInOrgTimeZone).utc(),
			end: new momentTz(endTimeInOrgTimeZone).utc(),
			patientFriendlyModality: scanType.Name,
		};
		submitData.familyMemberHash = patient?.profileId;
	}
	return submitData;
};

export const getLocation = setHasLocationPermission =>
	new Promise((resolve, _reject) => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				position => {
					resolve(position);
					setHasLocationPermission(true);
				},
				() => {
					setHasLocationPermission(false);
				}
			);
		} else {
			setHasLocationPermission(false);
		}
	});

export const getFacilitiesByLocation = async (
	__config,
	userLocation,
	setHasLocationPermission,
	setMapDefaultCenter,
	setShowUserMarker,
	patient,
	scanType,
	form,
	setIsLoadingFacility,
	setFacilities,
	organizationId
) => {
	let latitude;
	let longitude;
	if (userLocation) {
		if (userLocation?.place_id === 'current_location') {
			const location = await getLocation(setHasLocationPermission);
			if (location) {
				latitude = location.coords.latitude;
				longitude = location.coords.longitude;
			} else {
				latitude = 12.843774508382714;
				longitude = 77.66221950654179;
			}
		} else {
			const geoService = new window.google.maps.Geocoder();
			await geoService.geocode({ placeId: userLocation?.place_id }, predictions => {
				if (predictions) {
					latitude = predictions[0]?.geometry?.location.lat();
					longitude = predictions[0]?.geometry?.location.lng();
				}
			});
		}
		setMapDefaultCenter({
			lat: latitude,
			lng: longitude,
		});
		setShowUserMarker(true);
	}
	if (scanType && longitude && latitude) {
		form.setValue('facility', '');
		setIsLoadingFacility(true);
		let requestUrl = '';
		let orgId = organizationId ?? sessionStorage.getItem('organization');
		if (!orgId) {
			orgId = '';
		}
		requestUrl = `${__config.data_sources.blume}Appointment/findOrganizationsByServiceModalitiesAndPreference?${
			patient ? 'familyMemberHash=' + patient?.profileId + '&' : ''
		}serviceModalities=${scanType.DicomModalities.toString()}&userLat=${latitude}&userLong=${longitude}&organizationId=${orgId}`;

		axios
			.get(
				requestUrl,
				!patient && {
					headers: { 'X-API-KEY': window.btoa(__config.patient_portal.authentication_key) },
				}
			)
			.then(result => {
				const facilityResultList = result.data;
				if (facilityResultList?.length > 0) {
					facilityResultList?.forEach(eachFacility => {
						const addressJson = JSON.parse(eachFacility.extJson).address[0];
						const isAddressPresent = addressJson.line || addressJson.city;
						eachFacility.address =
							(addressJson.line ? addressJson.line[0] : '') +
							(addressJson.city ? ` ${addressJson.city}` : '') +
							(isAddressPresent != null ? '- ' : '');
						eachFacility.lat = addressJson.latitude;
						eachFacility.lng = addressJson.longitude;
						eachFacility.distance = clacDistance(eachFacility.distance, addressJson?.country || 'canada');
					});
					const key = 'internalOrganizationID';
					const uniqueFacilityList = [...new Map(facilityResultList.map(item => [item[key], item])).values()];
					setFacilities(uniqueFacilityList);
					if (uniqueFacilityList?.length) form.setValue('facility', uniqueFacilityList[0]);
				} else {
					setFacilities([]);
				}
				setIsLoadingFacility(false);
			});
	}
};

export const getLocationLabel = option => {
	let label = '';
	if (option?.description) {
		label = option.description;
		if (label === 'Use my current location') {
			return option.value;
		}
	}
	return label;
};

export const initialize = async (
	__config,
	setGenders,
	setHasLocationPermission,
	setMapDefaultCenter,
	setShowUserMarker
) => {
	const valueSetResponse = await axios.get(`${__config.data_sources.blume}ValueSet?criteria=name:exact=sex`);
	const codes = valueSetResponse?.data?.entry[0]?.resource?.compose?.include[0]?.concept || [];
	setGenders(codes.map(item => item.display));

	if (!navigator.permissions) {
		return;
	}
	navigator.permissions.query({ name: 'geolocation' }).then(PermissionStatus => {
		if (PermissionStatus.state !== 'granted') {
			setMapDefaultCenter({});
			return;
		}
		setHasLocationPermission(true);

		navigator.geolocation.getCurrentPosition(
			position => {
				setShowUserMarker(true);
				setMapDefaultCenter({
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				});
			},
			() => {
				setMapDefaultCenter({});
			}
		);
	});
};

export const fetchNotifications = async (__config, setNotifications) => {
	try {
		const result = await axios.get(`${__config.data_sources.blume}Notification`);
		if (result?.status !== 200) return;
		const results = result?.data;
		if (!results?.length) return;
		const oaiNotifications = results?.filter(
			item =>
				item?.properties?.NotificationType === notificationType.OAI_PATIENT_SYNC ||
				item?.properties?.NotificationType === notificationType.OAI_PATIENT_DATA_SYNC
		);
		if (oaiNotifications?.length) setNotifications([...oaiNotifications]);
	} catch (err) {
		console.error('error fetching notifications ', e);
	}
};

export const attachFile = async (id, files, setTriggerDocumentSectionRefresh, user, setToastMsg, __config) => {
	try {
		const fileData = new FormData();
		if (!files?.length || !id) return null;
		for (const file of files) {
			fileData.append('files', file);
		}
		await addAttachments({ __config, appointmentId: id, fileData, userHash: user?.profileId });
		sendAnalyticsEvent(UPLOAD_DOCUMENT, { appointmentId: id, totalFiles: files?.length });
		setTriggerDocumentSectionRefresh(true);
	} catch (err) {
		setToastMsg(err.message || 'Something might went wrong!');
		sendAnalyticsEvent(UPLOAD_DOCUMENT_FAILURE, {
			appointmentId: id,
			message: err.message || 'Something might went wrong!',
		});
	}
};

export const processResponse = async ({
	response,
	setAppointmentResponse,
	setTriggerDocumentSectionRefresh,
	setShowSuccess,
	setToastMsg,
	getAppointmentsFromStore,
	isUpdateForm,
	appointment,
	scanType,
	patient,
	userLocation,
	facility,
	t,
	phoenixBlumeRestrictHomeScreenFunctionality,
	user,
	files,
	__config,
}) => {
	if (response?.status === 200) {
		const organizationTimeZoneIANA = facility?.ianaTimezone || null;
		let startTimeInOrgTimeZone = null;
		let endTimeInOrgTimeZone = null;
		if (organizationTimeZoneIANA && Intl.DateTimeFormat().resolvedOptions().timeZone !== organizationTimeZoneIANA) {
			startTimeInOrgTimeZone = momentTz(response?.data?.start)
				.tz(organizationTimeZoneIANA)
				.format('YYYY-MM-DDTHH:mm:ss');
			endTimeInOrgTimeZone = momentTz(response?.data?.end)
				.tz(organizationTimeZoneIANA)
				.format('YYYY-MM-DDTHH:mm:ss');
		}
		const mappedAppointment = createAppointmentWithHealthcareInfo({ resource: response.data });
		const apptDetails = {
			...mappedAppointment,
			id: response?.data?.id,
			end: endTimeInOrgTimeZone ? new Date(endTimeInOrgTimeZone) : new Date(response?.data?.end),
			start: startTimeInOrgTimeZone ? new Date(startTimeInOrgTimeZone) : new Date(response?.data?.start),
			user: isUpdateForm ? appointment?.patient?.name : patient?.firstName,
			modality: isUpdateForm ? appointment?.type : scanType?.Name,
			location: isUpdateForm ? appointment?.organization : userLocation?.description,
		};
		setAppointmentResponse(apptDetails);
		sendAnalyticsEvent(isUpdateForm ? APPOINTMENT_RESCHEDULE_SUCCESS : APPOINTMENT_SUCCESS, apptDetails);
		await attachFile(response.data.id, files, setTriggerDocumentSectionRefresh, user, setToastMsg, __config);
		setShowSuccess(true);
		setToastMsg(isUpdateForm ? t('Appointment Rescheduled Successfully') : t('Appointment Requested Successfully'));
		getAppointmentsFromStore(phoenixBlumeRestrictHomeScreenFunctionality);
		cacheAppointmentResInLS(userLocation, scanType, isUpdateForm);
	} else {
		setToastMsg('Appointment Request Failed');
	}
};

const BLUME_APPOINTMENT_CACHE = 'BLUME_APPOINTMENT_CACHE';

export const cacheAppointmentResInLS = (userLocation, scanType, isUpdateForm) => {
	if (isUpdateForm) return;
	localStorage.setItem(BLUME_APPOINTMENT_CACHE, JSON.stringify({ scanType, userLocation }));
};

export const retrieveCacheData = form => {
	const data = localStorage.getItem(BLUME_APPOINTMENT_CACHE);
	if (!data) return false;
	const parsedData = JSON.parse(data);
	form.setValue('scanType', parsedData.scanType);
	form.setValue('userLocation', parsedData.userLocation);
};

export const processNotifications = (
	patient,
	notifications,
	setHasPendingCRs,
	setShowNotifications,
	setClubNotifications
) => {
	if (!patient?.profileId && !notifications?.length) return;
	const filteredNotifications = notifications?.filter(
		pData =>
			pData?.properties?.userHash === patient?.profileId && pData?.properties?.status?.toLowerCase() === 'pending'
	);
	const showPendingNots = Boolean(filteredNotifications?.filter(d => norAcceptedRejected(d))?.length);
	setHasPendingCRs(showPendingNots);
	setShowNotifications(showPendingNots);
	const clubbedNotificationsObj = {};
	for (const not of filteredNotifications) {
		const date = moment(not.generationTime).format('MM-DD-YYYY');
		if (clubbedNotificationsObj[date]) {
			clubbedNotificationsObj[date].push(not);
		} else {
			clubbedNotificationsObj[date] = [not];
		}
	}
	setClubNotifications(Object.values(clubbedNotificationsObj) || []);
};

export const showPatientName = option =>
	(
		(option?.firstName !== null ? `${option?.firstName} ` : '') +
		(option?.lastName !== null ? option?.lastName : '')
	).trim();

export const searchGooglePlaces = (query, setGoogleSearchResults, googleSearchResults) => {
	const service = new window.google.maps.places.AutocompleteService();
	service.getQueryPredictions({ input: query }, predictions => {
		setGoogleSearchResults(predictions || []);
	});
	return googleSearchResults;
};

export const uploadFiles = (e, phoenixBlumeTurnOffVideoFileUpload, setToastMsg, setFiles, t) => {
	const _files = e.target?.files;
	for (const acceptedFile of _files) {
		const fileType = acceptedFile.type;
		if (
			![
				...ALLOWED_DOCUMENT_MIME_TYPES,
				...(phoenixBlumeTurnOffVideoFileUpload ? [] : ALLOWED_DOCUMENT_VIDEO_MIME_TYPES),
			].includes(fileType)
		) {
			setToastMsg(t('File type not supported'));
			return;
		}
	}
	if (_files?.length) setFiles(prev => [...prev, ..._files]);
};
