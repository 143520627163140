import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { useAuth } from '@worklist-2/core/src';

import ShareDrawer from '../components/ShareDrawer/ShareDrawer';
import FormsDrawer from '../components/FormDrawer/v3';

const LoginRoute = lazy(() => import('./Login'));
const HomeRoute = lazy(() => import('./HomeV2'));
const EmailVerificationRoute = lazy(() => import('./EmailVerification'));
const StudyDetailLink = lazy(() => import('./StudyDetail/StudyDetailLink'));
const HelpCenterRoute = lazy(() => import('./HelpCenter'));
const UserSettingsRoute = lazy(() => import('./UserSettings'));
const AppointmentRoute = lazy(() => import('./Appointment'));
const StudyDetail = lazy(() => import('./StudyDetail/StudyDetail'));
const ImageViewerExternal = lazy(() => import('./ExternalImageViewerView/ExternalImageViewerView'));
const FormsExternal = lazy(() => import('./ExternalFormsView/ExternalFormsView'));
const ExternalViews = lazy(() => import('../views/ExternalViews'));
const BookAppointment = lazy(() => import('./Appointment/BookAppointmentPage'));

const SecuredRoute = ({ children }) => {
	const { isEmailVerified } = useAuth();

	if (isEmailVerified === null) {
		return null;
	}

	if (isEmailVerified) {
		return children;
	}

	return <EmailVerificationRoute />;
};

const ValidRoutes = () => {
	const portalRouteList = [
		{ path: '/login', element: <LoginRoute /> },
		{ path: '/signup', element: <LoginRoute /> },
		{
			path: '/',
			element: <Navigate to="/home" />,
		},
		{
			path: '/home',
			element: <HomeRoute />,
		},
		{
			path: '/book-appointment',
			element: <BookAppointment />,
		},
		{
			path: '/profile/*',
			element: <UserSettingsRoute />,
		},
		{
			path: '/study/link/:key',
			element: (
				<SecuredRoute>
					<StudyDetailLink />
				</SecuredRoute>
			),
		},
		{
			path: '/studyDetail/:id/:status/:fullScreen',
			element: <StudyDetail />,
		},
		{
			path: '/help/*',
			element: <HelpCenterRoute />,
		},
		{
			path: '/appointment',
			element: <AppointmentRoute />,
		},
		{
			path: '/externalImageViewer',
			element: <ImageViewerExternal />,
		},
		{
			path: '/external-form',
			element: <FormsExternal />,
		},
		{
			path: '/view-study',
			element: (
				<SecuredRoute>
					<ExternalViews />
				</SecuredRoute>
			),
		},
	].filter(Boolean);

	return (
		<>
			<Suspense
				fallback={
					<Skeleton
						sx={{
							height: '100%',
							width: '100%',
							borderRadius: '10px',
							margin: '24px 2.5% 0px 2.5%',
							left: '8px',
							right: '8px',
						}}
						variant="rectangular"
					/>
				}
			>
				<Routes>
					{portalRouteList.map(item => (
						<Route key={item.path} element={item.element} path={item.path} />
					))}
				</Routes>
			</Suspense>

			{/* Shared Components */}
			<ShareDrawer />
			<FormsDrawer />
		</>
	);
};

export default ValidRoutes;
