import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Details from '../utils/Details';
import { useNavigate } from 'react-router';
import { Button } from '@mui/material';
import appointmentSuccess from '@worklist-2/ui/src/assets/img/homepage/appointmentSuccess.png';
import appointmentFailure from '@worklist-2/ui/src/assets/img/homepage/appointmentFailure.png';
import { useScheduleStore } from '@worklist-2/patientPortal/src/stores';

const Status = ({ form }, ref) => {
	const { getValues } = form;
	const navigate = useNavigate();
	const { t } = useTranslation('scheduling');

	const { location, description, startTime, endTime, firstName, orderSetId } = getValues();
	const appointmentBooked = useScheduleStore(state => state.appointmentBooked);
	const jumpToStep = useScheduleStore(state => state.jumpToStep);
	const orgDetails = useScheduleStore(state => state.orgDetails);
	const selectedFacility = useScheduleStore(state => state.selectedFacility);
	const resetState = useScheduleStore(state => state.resetState);

	const onClose = () => {
		navigate('/');
		resetState();
	};

	const goBackToTimeSlotScreen = () => {
		jumpToStep(3);
	};

	return (
		<Box
			ref={ref}
			alignItems="center"
			display="flex"
			flexDirection="column"
			gap="28px"
			position="relative"
			sx={{
				maxWidth: '700px',
				margin: { xs: '0 20px 20px 20px', md: 'auto' },
				background: '#fff',
				padding: { xs: '20px', md: '40px' },
				boxShadow: '0px 4px 74px 0px rgba(0, 0, 0, 0.03)',
				borderRadius: '8px',
			}}
		>
			<Box display="flex" justifyContent="flex-end" position="absolute" right="20px" top="16px" onClick={onClose}>
				<IconButton>
					<CloseIcon />
				</IconButton>
			</Box>
			<Box>
				<img alt="search" src={appointmentBooked ? appointmentSuccess : appointmentFailure} width="140px" />
			</Box>
			<Box alignItems="center" display="flex" flexDirection="column" gap="8px" textAlign="center">
				{appointmentBooked ? (
					<Typography fontSize={{ xs: '18px', md: '28px' }} fontWeight={500} sx={{ color: '#101828' }}>
						{t('Appointment booked for')}{' '}
						<Typography
							component="span"
							fontSize={{ xs: '28px', md: '28px' }}
							fontWeight={500}
							sx={{ color: '#101828' }}
						>
							{firstName}
						</Typography>
					</Typography>
				) : (
					<Typography fontSize={{ xs: '18px', md: '28px' }} fontWeight={500} sx={{ color: '#101828' }}>
						{t('Appointment not booked')}
					</Typography>
				)}
				{appointmentBooked ? (
					<Typography
						fontSize={{ xs: '12px', md: '20px' }}
						lineHeight={{ xs: '18px', md: '30px' }}
						sx={{ color: '#344054' }}
					>
						{t('Details have been emailed to the patient, and the facility will confirm soon.')}
					</Typography>
				) : (
					<Typography
						fontSize={{ xs: '12px', md: '22px' }}
						lineHeight={{ xs: '18px', md: '30px' }}
						sx={{ color: '#344054' }}
					>
						{t(
							"We couldn't confirm your appointment at this time. Please try again or contact the facility directly for assistance."
						)}
					</Typography>
				)}
			</Box>
			{appointmentBooked ? (
				<Details
					disableNavigation
					address={selectedFacility.address}
					endTime={endTime}
					location={location}
					orderset={description}
					ordersetId={orderSetId}
					startTime={startTime}
				/>
			) : (
				<Button
					color="rsPrimary"
					data-testid="proceed-btn"
					fontSize={{ xs: '12px', md: '14px' }}
					sx={{
						width: { xs: '100%', md: '60%' },
					}}
					variant="contained"
					onClick={goBackToTimeSlotScreen}
				>
					{t('TRY BOOKING AGAIN')}
				</Button>
			)}
			<Box sx={{ width: '100%', color: '#344054', textAlign: 'left', fontStyle: 'italic' }}>
				{appointmentBooked && (
					<Box component="p">{t('Please arrive at least 15 minutes early to allow time for check-in.')}</Box>
				)}
				{appointmentBooked ? (
					<Box
						component="p"
						sx={{
							fontSize: { xs: '12px', md: '16px' },
							lineHeight: { xs: '18px', md: '24px' },
						}}
					>
						If you need to reschedule or have questions, contact us at{' '}
						<Box
							component="span"
							sx={{
								fontSize: { xs: '12px', md: '16px' },
								lineHeight: { xs: '18px', md: '24px' },
								fontWeight: '500',
							}}
						>
							{orgDetails?.phone}
						</Box>{' '}
						or visit{' '}
						<Box
							component="span"
							sx={{
								fontSize: { xs: '12px', md: '16px' },
								lineHeight: { xs: '18px', md: '24px' },
								fontWeight: '500',
							}}
						>
							{orgDetails?.website}
						</Box>
					</Box>
				) : (
					<Box
						component="p"
						sx={{
							fontSize: { xs: '12px', md: '16px' },
							lineHeight: { xs: '18px', md: '24px' },
						}}
					>
						If you continue to have issues, please reach out to the imaging facility for help, contact them
						at ,
						<Box
							component="span"
							sx={{
								fontSize: '18px',
								lineHeight: '18px',
								fontWeight: '500',
							}}
						>
							+1-212-456-7890
						</Box>{' '}
						or visit{' '}
						<Box
							component="span"
							sx={{
								fontSize: '18px',
								lineHeight: '18px',
								fontWeight: '500',
							}}
						>
							www.manipalhospitals.com
						</Box>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default forwardRef(Status);
