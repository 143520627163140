import { NoScrollbar, mainBox, boxStyle, header, sideStyle } from '@rs-ui/components/PrivacyPolicy/policyStyles';
import { SideBarLinks, TermsModalContent } from '@rs-ui/components/PrivacyPolicy/TermsModal';
import React, { useState } from 'react';
import Close from '@mui/icons-material/Close';
import ChevronLeftOutlined from '@mui/icons-material/ChevronLeftOutlined';
import { Box, Icon, IconButton, Typography } from '@mui/material';
import { Svg } from '@rs-ui/components';
import { useIsTablet } from '@worklist-2/core/src';
import {
	MobileTermsDrawerContent,
	MobileTermsDrawerSidebar,
} from '@rs-ui/components/Footer/components/MobileTermsDrawer';

const TermsServices = () => {
	const isTablet = useIsTablet();
	const [isShownContent, setIsShownContent] = useState(false);

	return isTablet ? (
		<Box sx={{ my: 4, mx: 2 }}>
			{isShownContent && (
				<Typography
					sx={{
						position: 'sticky',
						top: '0',
						fontWeight: '500',
						fontSize: '14px',
						lineHeight: '16px',
						letterSpacing: '0.1em',
						fontVariant: 'small-caps',
						color: '#666666',
						marginBottom: '14px',
						display: 'flex',
						alignItems: 'center',
						marginLeft: '8px',
						background: 'rgb(243, 243, 243)',
						zIndex: 6,
						py: 2,
					}}
					onClick={() => setIsShownContent(false)}
				>
					<Icon component={ChevronLeftOutlined} sx={{ marginRight: '8px' }} />
					GO BACK
				</Typography>
			)}
			{isShownContent ? (
				<MobileTermsDrawerContent />
			) : (
				<MobileTermsDrawerSidebar
					onClick={() => {
						setIsShownContent(true);
					}}
				/>
			)}
		</Box>
	) : (
		<Box sx={mainBox}>
			<NoScrollbar style={sideStyle}>
				<IconButton
					sx={{
						position: 'absolute',
						top: 10,
						right: 20,
					}}
				>
					<Close sx={{ color: '#000' }} />
				</IconButton>
				<SideBarLinks />
			</NoScrollbar>
			<Box sx={boxStyle}>
				<Svg
					name="terms"
					style={{
						width: 75,
						height: 75,
						marginTop: isTablet ? 0 : 100,
					}}
					viewBox="-10 -10 75 75"
				/>
				<Box component="h3" sx={header}>
					Terms of Service
				</Box>
				<TermsModalContent />
			</Box>
		</Box>
	);
};

export default TermsServices;
