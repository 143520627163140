// Core

import React from 'react';

// MUI

import { Box } from '@mui/material';

// Custom

import AppointmentDrawerContent from './AppointmentDrawerContent';
import { useAppointmentContext } from '../AppointmentContext/AppointmentContext';
import SearchDrawerContent from './SearchDrawerContent';
import ChatDrawerContent from './ChatDrawerContent';
import NotificationDrawerContent from './NotificationDrawerContent';
import BlumeAssistantDrawerContent from './BlumeAssistantDrawerContent';
import { mediaDesktopL } from '@worklist-2/core/src';

import ChatContextProvider from '@worklist-2/ui/src/components/Chat/ChatContext';
import CHAT_CONVERSATION_TYPES from '@worklist-2/ui/src/components/Chat//CHAT_CONVERSATION_TYPES';
import { useBlumeNavigationContext } from '@worklist-2/patientPortal/src/components/BlumeNavigationBar/BlumeNavigationContext';
import { useChatGlobalContext } from '@worklist-2/ui/src/components/Chat/ChatGlobalContext';
import { useDrawersStore } from '../../../stores';

const AppointmentDrawer = () => {
	const { isOpen, setIsOpen, isFullscreen } = useAppointmentContext();
	const searchText = useDrawersStore(state => state.searchText);

	if (searchText.length > 0) {
		setIsOpen('Search');
	}

	const { expanded } = useChatGlobalContext();
	const { setNavigationState } = useBlumeNavigationContext();

	const resolveDontFixActiveButtonShadow = React.useCallback(
		conversationType => !expanded && conversationType === CHAT_CONVERSATION_TYPES.CALL,
		[expanded]
	);

	const _conversationTypeChangeHandler = React.useCallback(
		conversationType => {
			setNavigationState({
				conversationType,
				dontFixActiveButtonShadow: resolveDontFixActiveButtonShadow(conversationType),
			});
		},
		[resolveDontFixActiveButtonShadow, setNavigationState]
	);

	return (
		<Box
			sx={{
				'--element-background-color': 'var(--app-drawer-background-color)',
				'--element-foreground-color': '#414141',
				'--element-foreground-color-secondary': '#717171',
				'--element-foreground-color-tertiary': '#a3a3a3',
				'--element-heading-foreground-color': '#121212',

				'--element-content-border-color': '#ececec',
				'--element-content-box-shadow-color': 'rgba(0, 0, 0, 0.1)',
				'--element-content-spacing': '20px',
				'--element-content-spacing-right': 'var(--element-content-spacing)',
				'--element-content-spacing-left': 'calc(var(--element-content-spacing) + var(--app-drawer-offset))',
				'--element-content-spacing-all':
					'var(--element-content-spacing) var(--element-content-spacing-right) var(--element-content-spacing) var(--element-content-spacing-left)',

				'--element-header-button-background-color': 'rgba(66, 165, 245, 0.08)',
				'--element-header-button-foreground-color': 'var(--color-primary)',

				'--element-field-background-color': '#f6f6f6',

				'--element-badge-border-color': '#ffffff',

				background: 'var(--element-background-color, #fcfcfc)',
				width: isOpen ? (isFullscreen ? 'calc(100% - 53px)' : '386px') : '0',
				minWidth: isOpen ? (isFullscreen ? 'calc(100% - 53px)' : '386px') : '0',
				height: '100vh',
				overflow: isOpen ? 'auto' : 'hidden',
				transition: 'left 0.2s, min-width 0.2s, transform 0.2s, width 0.2s',
				position: 'relative',
				zIndex: 'auto',
				'&::-webkit-scrollbar': {
					display: isOpen && 'none',
				},
				'&-ms-overflow-style:': {
					display: isOpen && 'none',
				},

				[mediaDesktopL]: {
					width: isOpen ? (isFullscreen ? 'calc(100% - 53px)' : '370px') : '0',
					minWidth: isOpen ? (isFullscreen ? 'calc(100% - 53px)' : '370px') : '0',
					zIndex: 8,
				},
			}}
		>
			<ChatContextProvider
				showAvatarsInExpandedMessagingView
				showAvatarsInNormalMessagingView
				showNewChatCopyLink
				showFiles={false}
				showFormSubmitButtonInField={false}
				onConversationTypeChange={_conversationTypeChangeHandler}
			>
				{(() => {
					switch (isOpen) {
						case 'Search':
							return <SearchDrawerContent setIsOpen={setIsOpen} />;

						case 'Scheduler':
							return <AppointmentDrawerContent />;

						case 'Chat':
							return (
								<ChatDrawerContent
									resolveDontFixActiveButtonShadow={resolveDontFixActiveButtonShadow}
								/>
							);

						case 'Notifications':
							return <NotificationDrawerContent setIsOpen={setIsOpen} />;
						case 'BlumeAssistant':
							return <BlumeAssistantDrawerContent />;
					}
				})()}
			</ChatContextProvider>
		</Box>
	);
};

export default AppointmentDrawer;
